import { KIND } from 'baseui/button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import moment from 'moment';

const CustomSupplyDateModal = ({
  isOpen,
  onClose,
  customSupplyDateItems,
  requisitionSupplyDate,
  onProceed,
}: any) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          },
        },
        Dialog: {
          style: {
            maxHeight: '85vh',
            overflowY: 'auto',
          },
        },
      }}
    >
      <ModalHeader>Custom Supply Date</ModalHeader>
      <ModalBody
        style={{
          marginBottom: '0px',
        }}
      >
        <p>
          The Default Supply date of the Requistion is{' '}
          {moment(requisitionSupplyDate).format('DD-MM-YYYY')}, but the below
          items have a custom supply date:
        </p>
        <div>
          {customSupplyDateItems.map((item: any) => (
            <div
              key={item.id}
              style={{
                margin: '5px 0',
              }}
            >
              {item.supplierProductCode} - {item.supplierProductName} -{' '}
              {item.supplyDate}
            </div>
          ))}
        </div>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          Do you want to proceed?
        </p>
      </ModalBody>
      <ModalFooter
        style={{
          marginTop: '0px',
        }}
      >
        <ModalButton kind={KIND.tertiary} onClick={onClose}>
          No
        </ModalButton>
        <ModalButton kind={KIND.primary} onClick={onProceed}>
          Yes
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default CustomSupplyDateModal;
