import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { KIND as ButtonKind, SHAPE as ButtonShape } from 'baseui/button';
import { useStyletron } from 'styletron-react';
import * as xlsx from 'xlsx';
import moment from 'moment';

type Props = {
  isOpen: boolean;
  products: Array<any>;
  setIsOpen: (isOpen: any) => void;
  getAllOrderLists: any;
};

const ExportOrderReport = ({
  isOpen,
  products,
  setIsOpen,
  getAllOrderLists,
}: Props) => {
  const isLoading = false;
  const [css] = useStyletron();

  const exportData = (exportProducts: Array<any>) => {
    const dataToExport: Array<any> = [];
    exportProducts.forEach(
      ({
        receivedAt,
        cancelledAt,
        orderCode,
        requisitionUsed,
        SupplyDate,
        orderStatus,
        isChangedAfterRelease,
        processingErrorCount,
        errorMessageAt,
        errorMessage,
        sendPOToVendor,
        releasedAt,
      }: any) => {
        dataToExport.push({
          'Received At': receivedAt
            ? moment(receivedAt).format('YYYY:MM:DD hh:mm a')
            : '',
          'Cancelled At': cancelledAt
            ? moment(cancelledAt).format('YYYY:MM:DD hh:mm a')
            : '',
          'Order Code': orderCode,
          'Requisition Used': requisitionUsed,
          'Supply Date': SupplyDate
            ? moment(SupplyDate).format('YYYY:MM:DD hh:mm a')
            : '',
          'Order Status': orderStatus,
          'Is Changed After Release': isChangedAfterRelease,
          'Processing Error Count': processingErrorCount,
          'Error Message At': errorMessageAt
            ? moment(errorMessageAt).format('YYYY:MM:DD hh:mm a')
            : '',
          'Error Message': errorMessage,
          'Send PO To Vendor': sendPOToVendor,
          'Released At': releasedAt
            ? moment(releasedAt).format('YYYY:MM:DD hh:mm a')
            : '',
        });
      }
    );
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Order Report');
    xlsx.writeFile(workbook, 'order_report.xlsx');
  };

  return (
    <>
      <Modal
        onClose={() => setIsOpen(false)}
        closeable={false}
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.auto}
        role={ROLE.dialog}
      >
        <ModalHeader>
          Export Products
          <ModalBody>
            <input
              className={css({
                marginLeft: '16px',
                cursor: isLoading ? 'not-allowed' : 'pointer',
                background: '#ececec',
                border: 'none',
                borderRadius: '16px',
                padding: '8px 16px',
                outline: 'none',
                fontSize: '1rem',
                color: '#00aeef',
                ':hover': {
                  background: isLoading ? '#ececec' : '#00aeef',
                  color: isLoading ? '#00aeef' : '#ffffff',
                  fontWeight: isLoading ? '400' : '600',
                },
              })}
              type="button"
              value="Export All"
              onClick={getAllOrderLists}
              disabled={isLoading}
            />
            <input
              className={css({
                marginLeft: '16px',
                cursor: isLoading ? 'not-allowed' : 'pointer',
                background: '#ececec',
                border: 'none',
                borderRadius: '16px',
                padding: '8px 16px',
                outline: 'none',
                fontSize: '1rem',
                color: '#00aeef',
                ':hover': {
                  background: isLoading ? '#ececec' : '#00aeef',
                  color: isLoading ? '#00aeef' : '#ffffff',
                  fontWeight: isLoading ? '400' : '600',
                },
              })}
              type="button"
              value="Export Current Page"
              onClick={() => exportData(products)}
              disabled={isLoading}
            />
          </ModalBody>
        </ModalHeader>
        <ModalFooter>
          <ModalButton
            type="button"
            shape={ButtonShape.pill}
            kind={ButtonKind.secondary}
            onClick={() => setIsOpen(false)}
            disabled={isLoading}
          >
            Cancel
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ExportOrderReport;
