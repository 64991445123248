import React from 'react';
import { useStyletron } from 'styletron-react';

import colors from 'constants/colors';

interface IProps {
  icon: string;
  name: string;
  isDisable?: boolean;
  onClick: () => void;
}

const CustomIconButton = ({
  icon,
  name,
  onClick,
  isDisable = false,
}: IProps) => {
  const [css] = useStyletron();

  const wrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(13, 38, 107, 0.1)',
    borderRadius: '10px',
    backgroundColor: colors.white,
    padding: '6px 12px',
    cursor: 'pointer',
    height: 'fit-content',
    '@media (max-width: 600px)': {
      padding: '4px 10px',
    },
  });

  const DisableWrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(13, 38, 107, 0.1)',
    borderRadius: '10px',
    backgroundColor: '#f5f3f3',
    padding: '6px 12px',
    cursor: 'not-allowed',
    height: 'fit-content',
    '@media (max-width: 600px)': {
      padding: '4px 10px',
    },
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    marginRight: '8px',
    filter:
      'invert(31%) sepia(61%) saturate(337%) hue-rotate(182deg) brightness(87%) contrast(88%)',
    '@media (max-width: 600px)': {
      padding: '8px',
      width: '17px',
      height: '17px',
      marginRight: '6px',
    },
  });
  const buttonStyles = css({
    border: 'none',
    outline: 'none',
    fontSize: '16px',
    fontFamily: 'poppins-regular',
    color: colors.darkBlue,
    backgroundColor: colors.white,
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  });
  const disableButtonStyles = css({
    border: 'none',
    outline: 'none',
    fontSize: '16px',
    fontFamily: 'poppins-regular',
    color: colors.darkBlue,
    backgroundColor: '#f5f3f3',
    cursor: 'not-allowed',
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  });

  return (
    <div>
      {!isDisable && (
        <div className={wrapperStyles} onClick={onClick}>
          <img className={iconStyles} src={icon} alt={name} />
          <button className={buttonStyles} type="button">
            {name}
          </button>
        </div>
      )}
      {isDisable && (
        <div className={DisableWrapperStyles} onClick={onClick}>
          <img className={iconStyles} src={icon} alt={name} />
          <button className={disableButtonStyles} type="button" disabled>
            {name}
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomIconButton;
