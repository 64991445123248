import React, { useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import ordersIconURL from 'assets/icons/orders.svg';
import { Axios } from 'core/services/http';
import { dates } from 'modules/shared/constant';
import FilterList from './components/search';
import colors from 'constants/colors';
import filter from '../../../../assets/icons/filter.png';
import './index.scss';
import moment from 'moment';
import { toaster } from 'baseui/toast';
import PurchaseContributionPieChart from './components/pie-chart';
import { Spinner } from 'baseui/spinner';
import DataDisplayTable from './components/data-table';
import numberWithCommas from 'modules/shared/number-with-commas';

const BREADCRUMB_ITEMS = [
  { name: 'Reports', route: '/reports' },
  {
    name: 'Purchase Contribution',
    route: '/reports/purchaseContributionReport',
  },
];

interface ChartData {
  title: string;
  data: {
    id: string;
    label: string;
    value: number;
  }[];
  storeWiseData: any;
}

const PurchaseContributionReport = () => {
  const [purchaseContributionData, setPurchaseContributionData] =
    React.useState<Array<ChartData>>([]);
  const [storeWiseData, setStoreWiseData] = React.useState<Array<any>>([]);
  const [vendors, setVendors] = React.useState<Array<any>>([]);
  const [vendorsValue, setVendorsValue] = React.useState<Array<any>>([]);
  const [products, setProducts] = React.useState<Array<any>>([]);
  const [activeAllFilters, setActiveAllFilters] = useState(true);
  const [startDate, setStartDate] = React.useState<any>([
    new Date(moment().utc().clone().startOf('day').format()),
  ]);
  const [endDate, setEndDate] = React.useState<any>([new Date()]);
  const [dateOption, setDateOption] = React.useState<any>([]);
  const [isDateDisable, setIsDateDisable] = React.useState<any>(true);
  const [productsValue, setProductsValue] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [css] = useStyletron();
  const axios = new Axios().getInstance();
  const titleWrapper = css({
    display: 'flex',
    padding: '16px 16px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
  });
  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });

  const listWrapper = css({
    padding: '12px',
    '@media (max-width: 540px)': {
      overflow: 'scroll',
    },
  });
  const toggleFilter = css({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '12px 8px',
    borderRadius: '10px',
    background: activeAllFilters ? colors.darkGreen : colors.white,
    cursor: 'pointer',
    margin: '10px 0',
    boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  });
  const filterImg = css({
    width: '22px',
    filter: activeAllFilters
      ? 'brightness(0) invert(1)'
      : 'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });

  const getPurchaseContributionList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/report/purchase-contribution', {
        params: {
          startDate: startDate[0],
          endDate: endDate[0],
          vendorIds:
            vendorsValue.length > 0
              ? vendorsValue.map((item: any) => item.id)
              : vendors.length > 0
              ? vendors.map((item: any) => item.id)
              : [],
          vendorProductIds:
            productsValue.length > 0
              ? productsValue.map((item: any) => item.id)
              : [],
        },
      });
      if (response?.data?.success) {
        const data = response?.data?.data;
        const chartData: ChartData[] = data.map((item: any) => ({
          title: `${item?.vendorProductCode} - ${item?.vendorProductName} - ${item?.packagingUnitName}`,
          data: item.vendorContributions.map((vendor: any) => {
            const percentage =
              (vendor.orderQuantity / item.totalOrderQuantity) * 100;
            return {
              id: `${vendor.vendorName} - (Amount: ${numberWithCommas(
                vendor.totalAmount
              )})`,
              label: `${vendor.vendorName}`,
              value: vendor.orderQuantity,
              percentage: percentage.toFixed(2),
            };
          }),
          storeWiseData: item.storeContributions,
        }));
        setPurchaseContributionData(chartData);
        setIsLoading(false);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
      setIsLoading(false);
    }
  };
  const handleClear = () => {
    setDateOption([]);
    setVendorsValue([]);
    setProductsValue([]);
  };

  const onSubmit = () => {
    if (productsValue.length === 0) {
      toaster.negative('Please select product', {
        autoHideDuration: 3000,
      });
      return;
    }
    getPurchaseContributionList();
  };

  const getVendorsWhoSuppliesProduct = async () => {
    try {
      const response = await axios.get('/vendors/who-supplied-items', {
        params: {
          startDate: startDate[0],
          endDate: endDate[0],
          vendorProductIds:
            productsValue.length > 0
              ? productsValue.map((item: any) => item.id)
              : [],
        },
      });
      if (response?.data?.success) {
        const fetchedVendors = response.data.data;
        const vendorOptions = fetchedVendors.map((vendor: any) => ({
          label: vendor.name,
          id: vendor.id,
        }));
        setVendors(vendorOptions);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  useEffect(() => {
    if (productsValue.length > 0) {
      getVendorsWhoSuppliesProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsValue, startDate, endDate]);

  const getProducts = async () => {
    try {
      const response = await axios.get('/supplierProducts', {
        params: {
          all: true,
        },
      });
      if (response?.data?.success) {
        let data: any[] = response.data?.data?.products;
        data = data.map(
          ({
            id,
            supplierProductName,
            supplierProductCode,
            packagingUnit: { name },
          }) => ({
            label: `${supplierProductCode} - ${supplierProductName} - ${name}`,
            id,
          })
        );
        setProducts(data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };
  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProductChange = (selectedOptions: any) => {
    setProductsValue(selectedOptions.value);
  };
  const handleVendorChange = (selectedOptions: any) => {
    setVendorsValue(selectedOptions.value);
  };

  return (
    <div className={css({ marginTop: '70px' })}>
      <div className={titleWrapper}>
        <div className={breadcrumbWrapper}>
          <img className={iconStyles} src={ordersIconURL} alt="Order" />
          <Breadcrumb items={BREADCRUMB_ITEMS} />
        </div>
        <div
          className={toggleFilter}
          onClick={() => setActiveAllFilters(!activeAllFilters)}
        >
          <img src={filter} alt="Filter" title="Filter" className={filterImg} />
        </div>
      </div>
      <div
        // ref={contentRef}
        className={`purchase-contribution ${
          activeAllFilters ? 'visible' : 'hidden'
        }`}
      >
        <div className={listWrapper}>
          <FilterList
            dates={dates}
            startDate={startDate}
            endDate={endDate}
            dateOption={dateOption}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setDateOption={setDateOption}
            onSubmit={onSubmit}
            vendors={vendors}
            handleVendorChange={handleVendorChange}
            vendorsValue={vendorsValue}
            products={products}
            productsValue={productsValue}
            handleProductChange={handleProductChange}
            handleClear={handleClear}
            isDateDisable={isDateDisable}
            setIsDateDisable={setIsDateDisable}
          />
        </div>
      </div>
      {!isLoading && purchaseContributionData.length > 0 && (
        <div className="chart-container">
          {purchaseContributionData.map((item, key) => {
            return (
              <div>
                <h3 className="chart-title">{item.title}</h3>
                <div
                  className={css({
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                  })}
                >
                  <PurchaseContributionPieChart // @ts-ignore
                    title={item.title}
                    colorScheme="nivo" // @ts-ignore
                    data={item.data}
                  />
                  <div
                    style={{
                      flex: 1,
                      padding: '16px',
                      paddingTop: 40,
                    }}
                  >
                    <DataDisplayTable data={item.storeWiseData} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {!isLoading && purchaseContributionData.length === 0 && (
        <div className="no-data">
          <h3>No data found</h3>
        </div>
      )}
      {isLoading && (
        <div className="loading">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default PurchaseContributionReport;
