import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'styletron-react';
import { StatefulPopover, PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { AppContext, DispatcherAction } from 'context';
import { AuthService } from 'core/services/auth';
import colors from 'constants/colors';
import { Axios } from 'core/services/http';

const UserProfile = ({ children }: { children: React.ReactNode }) => {
  const { dispatcher } = useContext(AppContext);
  const [css] = useStyletron();
  const navigate = useNavigate();
  const authService = new AuthService();
  const axios = new Axios().getInstance();

  const logoutClickedHandler = async () => {
    await axios.post('/auth/signOut');
    authService.logout();
    dispatcher(DispatcherAction.Logout);
    navigate('/');
  };

  const myAccountBtn = css({
    cursor: 'pointer',
    padding: '12px 16px',
    margin: 'auto',
    ':hover': {
      backgroundColor: colors.primaryBlue,
      color: colors.white,
    },
  });
  const userWrapper = css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  });

  return (
    <>
      <StatefulPopover
        placement={PLACEMENT.bottom}
        triggerType={TRIGGER_TYPE.click}
        content={
          <>
            <div
              className={css({
                marginTop: '16px',
                marginBottom: '12px',
              })}
            >
              <p
                className={myAccountBtn}
                onClick={() => navigate('my-account')}
              >
                My Account
              </p>
              <p className={myAccountBtn} onClick={logoutClickedHandler}>
                Logout
              </p>
            </div>
          </>
        }
        accessibilityType={'tooltip'}
      >
        <div className={userWrapper}>{children}</div>
      </StatefulPopover>
    </>
  );
};

export default UserProfile;
