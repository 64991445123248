import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'styletron-react';

import colors from 'constants/colors';
import arrowRightIconURL from 'assets/icons/arrow-right.svg';

interface IProps {
  items: Array<{ name: string; route: string }>;
}
const Breadcrumb = ({ items }: IProps) => {
  const navigate = useNavigate();
  const [css] = useStyletron();

  const navigateToRoute = (route: string, index: number) => {
    if (items.length - 1 === index) {
      return;
    }
    navigate(route);
  };

  const itemsWrapper = css({
    display: 'flex',
  });
  const itemWrapper = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
  });
  const buttonStyles = css({
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    fontFamily: 'poppins-medium',
  });
  const iconStyles = css({
    width: '16px',
    height: '16px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });

  return (
    <div className={itemsWrapper}>
      {items.map(({ name, route }, index) => (
        <div key={index} className={itemWrapper}>
          <button
            className={buttonStyles}
            style={{
              color:
                items.length - 1 !== index ? colors.darkBlue : colors.black,
            }}
            type="button"
            onClick={() => navigateToRoute(route, index)}
          >
            {name}
          </button>
          {items.length - 1 !== index ? (
            <img className={iconStyles} src={arrowRightIconURL} alt={name} />
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;
