import React from 'react';
import { Button } from 'baseui/button';
import { ChevronDown, ChevronRight } from 'baseui/icon';
import { withStyle, useStyletron } from 'baseui';
import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid';
import numberWithCommas from 'modules/shared/number-with-commas';

function buildRow(store: any) {
  return [
    store.storeName,
    store.orderQuantity,
    store.totalAmount,
    store.vendorContributions.map((vendor: any) => {
      const percentageContribution =
        (vendor.orderQuantity / store.orderQuantity) * 100;
      return [
        vendor.vendorName,
        percentageContribution.toFixed(2),
        vendor.orderQuantity.toFixed(1),
        vendor.totalAmount,
      ];
    }),
  ];
}

function Vendors(props: { vendors: any[] }) {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        gridColumn: 'span 3',
        padding: '32px 24px',
      })}
    >
      <StyledTable
        role="grid"
        $gridTemplateColumns="max-content auto auto auto"
      >
        <div role="row" className={css({ display: 'contents' })}>
          <StyledHeadCell $sticky={false}>Vendor Name</StyledHeadCell>
          <StyledHeadCell
            $sticky={false}
            style={{
              textAlign: 'right',
            }}
          >
            % Contribution
          </StyledHeadCell>
          <StyledHeadCell
            $sticky={false}
            style={{
              textAlign: 'right',
            }}
          >
            Quantity
          </StyledHeadCell>
          <StyledHeadCell
            $sticky={false}
            style={{
              textAlign: 'right',
            }}
          >
            Total Amount
          </StyledHeadCell>
        </div>
        {props.vendors.map((vendor) => {
          return (
            <div role="row" className={css({ display: 'contents' })}>
              <StyledBodyCell>{vendor[0]}</StyledBodyCell>
              <StyledBodyCell
                style={{
                  textAlign: 'right',
                }}
              >
                {vendor[1]}%
              </StyledBodyCell>
              <StyledBodyCell
                style={{
                  textAlign: 'right',
                }}
              >
                {vendor[2]}
              </StyledBodyCell>
              <StyledBodyCell
                style={{
                  textAlign: 'right',
                }}
              >
                {numberWithCommas(vendor[3])} AED
              </StyledBodyCell>
            </div>
          );
        })}
      </StyledTable>
    </div>
  );
}
const CenteredBodyCell = withStyle(StyledBodyCell, {
  display: 'flex',
  alignItems: 'center',
});
function Row({ striped, row, expandedIndex, index, onExpand }: any) {
  const [css] = useStyletron();
  return (
    <div role="row" className={css({ display: 'contents' })}>
      <CenteredBodyCell $striped={striped}>
        <Button
          size="compact"
          kind="tertiary"
          onClick={() => onExpand(index)}
          shape="square"
        >
          {expandedIndex === index ? (
            <ChevronDown size={18} />
          ) : (
            <ChevronRight size={18} />
          )}
        </Button>
        {row[0]}
      </CenteredBodyCell>
      <StyledBodyCell
        $striped={striped}
        style={{
          textAlign: 'right',
        }}
      >
        {row[1].toFixed(1)}
      </StyledBodyCell>
      <StyledBodyCell
        $striped={striped}
        style={{
          textAlign: 'right',
        }}
      >
        {numberWithCommas(row[2])} AED
      </StyledBodyCell>
      {expandedIndex === index && <Vendors vendors={row[3]} />}
    </div>
  );
}

const DataDisplayTable = ({ data }: any) => {
  const [css] = useStyletron();

  const [expandedIndex, setExpandedIndex] = React.useState(0);

  const newData = data.map((store: any) => {
    return buildRow(store);
  });

  return (
    <div
      className={css({
        width: '100%',
        overflow: 'auto',
      })}
    >
      <StyledTable role="grid" $gridTemplateColumns="max-content auto auto">
        <div
          role="row"
          className={css({
            display: 'contents',
          })}
        >
          <StyledHeadCell>Store Name</StyledHeadCell>
          <StyledHeadCell
            style={{
              justifyContent: 'flex-end',
            }}
          >
            Order Quantity
          </StyledHeadCell>
          <StyledHeadCell
            style={{
              justifyContent: 'flex-end',
            }}
          >
            Total Amount
          </StyledHeadCell>
        </div>
        {newData.map((row: any, index: any) => {
          // const striped = index % 2 === 0;
          return (
            <Row
              row={row}
              striped={true}
              expandedIndex={expandedIndex}
              index={index}
              onExpand={(index: any) => setExpandedIndex(index)}
            />
          );
        })}
      </StyledTable>
    </div>
  );
};

export default DataDisplayTable;
