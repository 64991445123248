import React from 'react';
import { useStyletron } from 'styletron-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toaster } from 'baseui/toast';

import Toolbar from 'modules/shared/components/navigation/toolbar';
import SideBar from 'modules/shared/components/navigation/sidebar';
import { AppContext, DispatcherAction } from 'context';
import colors from 'constants/colors';
import { event } from 'core/events';
import {
  ON_JWT_EXPIRED,
  ON_JWT_REFRESHED,
  ON_NETWORK_ERROR,
  ON_SERVER_ERROR,
} from 'constants/events';
import { AuthService } from 'core/services/auth';

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const {
    state: { token },
    dispatcher,
  } = React.useContext(AppContext);
  const [isActiveSidebar, setIsActiveSidebar] = React.useState(false);
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [hasServerError, setHasServerError] = React.useState(false);
  const [css] = useStyletron();
  const authService = new AuthService();
  const navigate = useNavigate();

  const rootWrapper = css({ display: 'flex' });
  const sidebarWrapper = css({
    display: 'block',
    width: '100%',
    maxWidth: '265px',
    position: 'fixed',
    transition: 'all 0.3s ease-in-out',
    '@media (max-width: 1000px)': {
      display: `${isActiveSidebar ? 'block' : 'none'}`,
      left: `${isActiveSidebar ? '0px' : '-550px'}`,
      maxWidth: '100vw',
    },
    '@media (max-width: 500px)': {
      display: `${isActiveSidebar ? 'block' : 'none'}`,
      left: `${isActiveSidebar ? '0px' : '-550px'}`,
      maxWidth: '100vw',
      zIndex: 400,
    },
  });
  const mainWrapper = css({
    display: 'block',
    width: '100%',
    marginLeft: isAuthenticated && !hasServerError ? '260px' : 'unset',
    '@media (max-width: 1000px)': {
      marginLeft: '0',
      marginTop: '30px',
    },
  });

  const burgerIconContainer = css({
    cursor: 'pointer',
    zIndex: '999',
  });
  const burgerIcon = css({
    backgroundColor: colors.primaryBlue,
    width: '24px',
    height: '4px',
    borderRadius: '2px',
    display: 'block',
    marginTop: '4px',
  });
  const menuWrapper = css({
    width: '35px',
    height: '35px',
    top: '70px',
    right: `${isActiveSidebar ? '16px' : ''}`,
    transition: 'all 0.3s ease-in-out',
    zIndex: 500,
    position: 'absolute',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1000px)': {
      display: 'flex',
    },
    '@media (max-width: 370px)': {
      top: '82px',
    },
  });

  const jWTExpiredHandler = () => {
    authService.setAuthToken('');
    dispatcher(DispatcherAction.Logout);
  };

  const jWTRefreshedHandler = () => {
    dispatcher(DispatcherAction.JWTRefresh);
  };

  const networkErrorHandler = () => {
    toaster.negative(
      'API is not available at the moment. Please try again later.',
      {
        autoHideDuration: 4000,
      }
    );
  };

  const serverErrorHandler = () => {
    setHasServerError(true);
    navigate('error');
  };

  React.useEffect(() => {
    setIsAuthenticated(token && token !== '');
  }, [token]);

  React.useEffect(() => {
    document.body.style.background = 'white';
  }, [location]);

  React.useEffect(() => {
    event.subscribe(ON_JWT_EXPIRED, jWTExpiredHandler);
    event.subscribe(ON_JWT_REFRESHED, jWTRefreshedHandler);
    event.subscribe(ON_NETWORK_ERROR, networkErrorHandler);
    event.subscribe(ON_SERVER_ERROR, serverErrorHandler);

    return () => {
      event.unsubscribe(ON_JWT_EXPIRED, jWTExpiredHandler);
      event.unsubscribe(ON_JWT_REFRESHED, jWTRefreshedHandler);
      event.unsubscribe(ON_NETWORK_ERROR, networkErrorHandler);
      event.unsubscribe(ON_SERVER_ERROR, serverErrorHandler);
    };
  });

  return (
    <React.Fragment>
      {isAuthenticated && !hasServerError ? (
        <>
          <div
            className={menuWrapper}
            onClick={() => setIsActiveSidebar(!isActiveSidebar)}
          >
            <p className={burgerIconContainer}>
              <span className={burgerIcon}></span>
              <span className={burgerIcon}></span>
              <span className={burgerIcon}></span>
            </p>
          </div>
          <Toolbar />
        </>
      ) : null}
      <div className={rootWrapper}>
        {isAuthenticated && !hasServerError ? (
          <div
            className={sidebarWrapper}
            onClick={() => setIsActiveSidebar(!isActiveSidebar)}
          >
            <SideBar />
          </div>
        ) : null}
        <div className={mainWrapper}>
          <main>{children}</main>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
