import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { AppContext, DispatcherAction } from 'context';
import { AuthService } from 'core/services/auth';
import { UserRole } from '../../../enums/user-role.enum';

import colors from 'constants/colors';
import SideBarLink from './sidebar-link';
import invoicesIconURL from 'assets/icons/invoices.svg';
import invoicesActiveIconURL from 'assets/icons/invoices-white.svg';
import ordersIconURL from 'assets/icons/orders.svg';
import ordersActiveIconURL from 'assets/icons/orders-white.svg';
import conflictIconURL from 'assets/icons/conflict.svg';
import conflictActiveIconURL from 'assets/icons/conflict-white.svg';
import dashboardIconURL from 'assets/icons/dashboard.svg';
import dashboardActiveIconURL from 'assets/icons/dashboard-white.svg';
import requisitionsIconURL from 'assets/icons/requisitions.svg';
import requisitionsActiveIconURL from 'assets/icons/requisition-white.svg';
import templatesIconURL from 'assets/icons/templates.svg';
import templatesActiveIconURL from 'assets/icons/templates-white.svg';
import priceListIconURL from 'assets/icons/price-list.svg';
import priceListActiveIconURL from 'assets/icons/price-list-white.svg';
import masterIconURL from 'assets/icons/master.svg';
import masterActiveIconURL from 'assets/icons/master-white.svg';
import setupIconURL from 'assets/icons/setup.svg';
import setupWhiteIconURL from 'assets/icons/setup-white.svg';
import helpCenterIconURL from 'assets/icons/help-center.svg';
import logoutIconURL from 'assets/icons/logout.svg';
import reportsIcon from 'assets/icons/reports.svg';
import reportsIconActive from 'assets/icons/reports-white.svg';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { Button, KIND } from 'baseui/button';
import { Axios } from 'core/services/http';
import moment from 'moment';

const NAVIGATION_LINKS = [
  {
    id: 1,
    icon: dashboardIconURL,
    iconActive: dashboardActiveIconURL,
    name: 'Dashboard',
    route: '/dashboard',
    isActive: false,
  },
  {
    id: 2,
    icon: requisitionsIconURL,
    iconActive: requisitionsActiveIconURL,
    name: 'Requisitions',
    route: '/requisitions',
    isActive: false,
  },
  {
    id: 3,
    icon: ordersIconURL,
    iconActive: ordersActiveIconURL,
    name: 'Orders',
    route: '/orders',
    isActive: false,
  },
  {
    id: 4,
    icon: conflictIconURL,
    iconActive: conflictActiveIconURL,
    name: 'Conflict Invoices',
    route: '/conflict-invoices',
    isActive: false,
  },
  {
    id: 5,
    icon: invoicesIconURL,
    iconActive: invoicesActiveIconURL,
    name: 'Invoices',
    route: '/invoices',
    isActive: false,
  },
  {
    id: 6,
    icon: templatesIconURL,
    iconActive: templatesActiveIconURL,
    name: 'Templates',
    route: '/templates',
    isActive: false,
  },
  {
    id: 7,
    icon: priceListIconURL,
    iconActive: priceListActiveIconURL,
    name: 'Price List',
    route: '/priceList',
    isActive: false,
  },
  {
    id: 8,
    icon: masterIconURL,
    iconActive: masterActiveIconURL,
    name: 'Master',
    route: '/master',
    isActive: false,
  },
  {
    id: 9,
    icon: setupIconURL,
    iconActive: setupWhiteIconURL,
    name: 'Setup',
    route: '/setup',
    isActive: false,
  },
  {
    id: 10,
    icon: reportsIcon,
    iconActive: reportsIconActive,
    name: 'Reports',
    route: '/reports',
    isActive: false,
  },
];
const ROUTE_IDS = {
  [UserRole.Admin]: [1, 2, 3, 4, 5, 7, 8, 10],
  [UserRole.User]: [1, 2, 3, 4, 5, 10],
  [UserRole.ManagementReadOnly]: [1, 2, 3, 4, 5, 10],
  [UserRole.PurchaseSupport]: [1, 2, 3, 4, 5, 10],
};

const SideBar = () => {
  const axios = new Axios().getInstance();
  const [css] = useStyletron();
  const { state, dispatcher } = useContext(AppContext);
  const navigate = useNavigate();
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [foundRoute, setFoundRoute] = useState<any>();
  const [currentVersion, setCurrentVersion] = useState<any>();
  const [isVersionDetailModalOpen, setIsVersionDetailModalOpen] =
    useState(false);

  const authService = new AuthService();
  const [navigationLinks, setNavigationLinks] =
    React.useState<Array<any>>(NAVIGATION_LINKS);

  // const navigateToRoute = (id: number) => {
  //   const found = navigationLinks.find((link) => id === link.id);
  //   const tempList = [...navigationLinks];
  //   tempList.forEach((item) => {
  //     item.isActive = item.id === id;
  //   });
  //   setNavigationLinks(tempList);
  //   navigate(found.route);
  // };

  // ===========Updated code===========
  const navigateToRoute = (id: number) => {
    console.log('clicked in');
    console.log('clicked in', warningPopUp);

    const found = navigationLinks.find((link) => id === link.id);
    const tempList = [...navigationLinks];
    tempList.forEach((item) => {
      item.isActive = item.id === id;
    });
    setNavigationLinks(tempList);
    // if (warningPopUp) {
    //   if (!warningPopUp) {
    //     localStorage.removeItem('unsaved');
    //     navigate(found.route);
    //   }
    // } else {
    //   navigate(found.route);
    // }
    if (localStorage.getItem('unsaved')) {
      setFoundRoute(found.route);
      setWarningPopUp(true);
    }
    if (!warningPopUp && !localStorage.getItem('unsaved')) {
      navigate(found.route);
    }
  };

  const getCurrentVersion = async () => {
    try {
      const response = await axios.get('/version-history/current');
      if (response?.data?.success) {
        setCurrentVersion(response?.data?.data);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          console.log('message', message);
        });
      }
    }
  };

  useEffect(() => {
    getCurrentVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.getItem('unsaved') === 'true') {
      setWarningPopUp(true);
      setUnsaved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('unsaved')]);

  const sidebarRootWrapper = css({
    padding: '90px 8px 8px 16px',
    minHeight: '100vh',
    '@media (max-width: 1000px)': {
      background: colors.white,
    },
    '@media (max-width: 370px)': {
      padding: '70px 0px 0px 0px',
    },
  });
  const navigationLinksWrapper = css({
    padding: '16px',
    borderRadius: '20px',
    boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.05)',
    backgroundColor: colors.white,
    '@media (max-width: 1000px)': {
      padding: '16px 16px 0px 16px',
      boxShadow: 'none',
    },
  });
  const logoutWrapperStyles = css({
    padding: '8px 16px',
    borderRadius: '12px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    backgroundColor: colors.white,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    marginRight: '8px',
  });
  const buttonStyles = css({
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontFamily: 'poppins-regular',
    fontSize: '16px',
    color: colors.darkBlue,
    cursor: 'pointer',
  });

  const labelStyles = css({
    fontFamily: 'poppins-regular',
    fontSize: '16px',
    color: colors.darkBlue,
  });

  const textStyles = css({
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    color: colors.darkBlue,
  });

  const handleWhatsAppRedirect = () => {
    window.open(
      `https://wa.me/971505592472?text=Hi, I am user ${state?.user?.email} and i need help with:`,
      '_blank'
    );
  };

  const logoutClickedHandler = () => {
    authService.logout();
    dispatcher(DispatcherAction.Logout);
    navigate('/');
  };
  const showLinksByUserRole = (routeId: number) => {
    switch (state?.user?.role) {
      case UserRole.SuperAdmin:
        return true;
      case UserRole.Admin:
        return ROUTE_IDS.Admin.includes(routeId);
      case UserRole.User:
        return ROUTE_IDS.User.includes(routeId);
      case UserRole.ManagementReadOnly:
        return ROUTE_IDS.ManagementReadOnly.includes(routeId);
      case UserRole.PurchaseSupport:
        return ROUTE_IDS.PurchaseSupport.includes(routeId);
      default:
        return false;
    }
  };
  return (
    <>
      <div className={sidebarRootWrapper}>
        <div className={navigationLinksWrapper}>
          {navigationLinks.map(
            ({ id, ...rest }) =>
              state?.user?.role &&
              showLinksByUserRole(id) && (
                <SideBarLink
                  key={id}
                  {...rest}
                  clicked={() => navigateToRoute(id)}
                />
              )
          )}
        </div>
        <br />
        <div className={logoutWrapperStyles}>
          <button
            className={buttonStyles}
            type="button"
            onClick={() => setIsVersionDetailModalOpen(true)}
          >
            Version: {currentVersion?.versionNumber}
          </button>
        </div>
        <br />
        <div className={logoutWrapperStyles}>
          <img
            className={iconStyles}
            src={helpCenterIconURL}
            alt="Help Center"
          />
          <button
            className={buttonStyles}
            type="button"
            onClick={() => handleWhatsAppRedirect()}
          >
            Help Center
          </button>
        </div>
        <br />
        <div className={logoutWrapperStyles}>
          <img className={iconStyles} src={logoutIconURL} alt="Logout" />
          <button
            className={buttonStyles}
            type="button"
            onClick={logoutClickedHandler}
          >
            Logout
          </button>
        </div>
      </div>
      <Modal
        isOpen={isVersionDetailModalOpen}
        onClose={() => setIsVersionDetailModalOpen(false)}
        overrides={{
          Dialog: {
            style: {
              minWidth: '60vw',
              maxHeight: '80vh',
              overflowY: 'auto',
            },
          },
          Root: {
            style: {
              zIndex: 999,
            },
          },
        }}
      >
        <ModalHeader>Version Details</ModalHeader>
        <ModalBody>
          <div>
            <h1 className={labelStyles}>Version Number: </h1>
            <p className={textStyles}>{currentVersion?.versionNumber}</p>
          </div>
          <div>
            <h1 className={labelStyles}>Short Description: </h1>
            <p className={textStyles}>
              {currentVersion?.versionShortDescription}
            </p>
          </div>
          <div>
            <h1 className={labelStyles}>Release Date: </h1>
            <p className={textStyles}>
              {moment(currentVersion?.releaseDate).format('DD-MM-YYYY')}
            </p>
          </div>
          <div>
            <h1 className={labelStyles}>Release Notes: </h1>
            <p
              className={css({
                fontFamily: 'poppins-regular',
                fontSize: '14px',
                color: colors.darkBlue,
                border: '1px solid #E5E5E5',
                borderRadius: '10px',
                padding: '10px',
              })}
              dangerouslySetInnerHTML={{ __html: currentVersion?.releaseNotes }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsVersionDetailModalOpen(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={warningPopUp} onClose={() => setWarningPopUp(false)}>
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>Changes will be lost if you cancel, Are you sure?</ModalBody>
        <ModalFooter>
          <Button kind={KIND.tertiary} onClick={() => setWarningPopUp(false)}>
            No
          </Button>
          <Button
            onClick={() => {
              setWarningPopUp(false);
              setUnsaved(false);
              localStorage.removeItem('unsaved');
              navigate(foundRoute);
              // closed();
              // Code to cancel the changes goes here
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SideBar;
