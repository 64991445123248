import { useEffect, useState } from 'react';
import './index.scss';
import menu from '../../../../../../assets/icons/dots.png';
import NotificationToggleMenu from '../components/notification-toggle-menu';
import { StyledTab, Tab, Tabs } from 'baseui/tabs';
import { LabelMedium } from 'baseui/typography';
import colors from 'constants/colors';
import NotificationCard from '../components/notificationCard';
import { Axios } from 'core/services/http';
import { INotification } from '../../../../interfaces/notification.interface';

interface INotifications {
  today: INotification[];
  yesterday: INotification[];
  older: INotification[];
}

function TabOverride({ children, ...rest }: any) {
  return (
    <StyledTab {...rest}>
      <LabelMedium
        overrides={{
          Block: {
            style: {
              color: 'inherit',
              ':hover': { color: 'inherit' },
            },
          },
        }}
      >
        {children}
      </LabelMedium>
    </StyledTab>
  );
}
const tabStyle = ({ $active, $disabled, $theme }: any) => ({
  color: $active ? colors.darkGreen : colors.darkBlue,
  borderBottomWidth: '3px',
  borderBottomStyle: 'solid',
  borderBottomColor: $active ? colors.darkGreen : 'transparent',
  paddingBottom: '7px',
  ':hover': $disabled
    ? {}
    : {
        color: colors.darkGreen,
      },
  ':focus': $disabled
    ? {}
    : {
        color: colors.darkGreen,
      },
});
const NotificationList = ({
  notifications,
  markAsRead,
}: {
  notifications: INotification[];
  markAsRead?: (id: string) => void;
}) => {
  return (
    <>
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.id}
          {...notification}
          markAsRead={markAsRead}
        />
      ))}
    </>
  );
};

interface IProps {
  notificationData: INotification[];
  refetch: () => void;
}
const Notifications = ({ notificationData, refetch }: IProps) => {
  const axios = new Axios().getInstance();
  const [activeMenu, setActiveMenu] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [tabValue, setTabValue] = useState('New');
  const [unreadNotifications, setUnreadNotifications] =
    useState<INotifications>({
      today: [],
      yesterday: [],
      older: [],
    });
  const [readNotifications, setReadNotifications] = useState<INotifications>({
    today: [],
    yesterday: [],
    older: [],
  });

  const markNotificationsAsRead = async (notificationIds: Array<string>) => {
    try {
      const response = await axios.patch('/notifications/read', {
        notificationIds,
      });
      if (response?.data?.success) {
        refetch();
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          console.log(message);
        });
      }
    }
  };

  const markAsRead = (type: 'all' | 'single', id?: string) => {
    if (type === 'all') {
      const ids = notifications.reduce(
        (acc: string[], current: INotification) => {
          if (!current.read) {
            acc.push(current.id);
          }
          return acc;
        },
        []
      );
      markNotificationsAsRead(ids);
    }
    if (id) {
      markNotificationsAsRead([id]);
    }
  };

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
    const todayNotifications: INotification[] = [];
    const yesterdayNotifications: INotification[] = [];
    const olderNotifications: INotification[] = [];

    notifications.forEach((notification) => {
      const notificationDate = new Date(notification.date);
      if (notificationDate.toDateString() === today.toDateString()) {
        if (notification.read) {
          setReadNotifications((prev) => ({
            ...prev,
            today: [...prev.today, notification],
          }));
        } else {
          todayNotifications.push(notification);
        }
      } else if (notificationDate.toDateString() === yesterday.toDateString()) {
        if (notification.read) {
          setReadNotifications((prev) => ({
            ...prev,
            yesterday: [...prev.yesterday, notification],
          }));
        } else {
          yesterdayNotifications.push(notification);
        }
      } else {
        if (notification.read) {
          setReadNotifications((prev) => ({
            ...prev,
            older: [...prev.older, notification],
          }));
        } else {
          olderNotifications.push(notification);
        }
      }
    });

    setUnreadNotifications({
      today: todayNotifications,
      yesterday: yesterdayNotifications,
      older: olderNotifications,
    });
  }, [notifications]);

  useEffect(() => {
    setNotifications(notificationData);
  }, [notificationData]);

  return (
    <div className="main-wrapper">
      <div className="main-wrapper-top-header">
        <h2 className="main-wrapper-top-header-heading">Notifications</h2>
        <img
          className="main-wrapper-top-header-menu"
          src={menu}
          alt="Menu"
          onClick={() => {
            setActiveMenu(!activeMenu);
          }}
        />
        {activeMenu && <NotificationToggleMenu />}
      </div>
      <Tabs
        activeKey={tabValue}
        onChange={(e: any) => setTabValue(e.activeKey)}
        overrides={{
          Tab: { component: TabOverride, style: tabStyle },
          TabBar: { style: { backgroundColor: 'white' } },
        }}
      >
        <Tab title="New" key="New">
          <p className="mark-read" onClick={() => markAsRead('all')}>
            Mark all as read
          </p>
          {unreadNotifications.today.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Today</h2>
          )}
          <NotificationList
            notifications={unreadNotifications.today}
            markAsRead={(id: string) => markAsRead('single', id)}
          />
          {unreadNotifications.yesterday.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Yesterday</h2>
          )}
          <NotificationList
            notifications={unreadNotifications.yesterday}
            markAsRead={(id: string) => markAsRead('single', id)}
          />
          {unreadNotifications.older.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Older</h2>
          )}
          <NotificationList
            notifications={unreadNotifications.older}
            markAsRead={(id: string) => markAsRead('single', id)}
          />
        </Tab>
        <Tab title="Read" key="Read">
          {readNotifications.today.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Today</h2>
          )}
          <NotificationList notifications={readNotifications.today} />
          {readNotifications.yesterday.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Yesterday</h2>
          )}
          <NotificationList notifications={readNotifications.yesterday} />
          {readNotifications.older.length !== 0 && (
            <h2 className="main-wrapper-notifications-heading">Older</h2>
          )}
          <NotificationList notifications={readNotifications.older} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Notifications;
