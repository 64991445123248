import React, { useEffect, useState } from 'react';
import { Button, KIND } from 'baseui/button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';
import { useStyletron } from 'styletron-react';
import numberWithCommas from 'modules/shared/number-with-commas';
import { Spinner } from 'baseui/icon';
import { Axios } from 'core/services/http';
import { Input, SIZE } from 'baseui/input';
import { toaster } from 'baseui/toast';

const SplitItemsModal = ({ isOpen, onClose, requisition, onSplit }: any) => {
  const [css] = useStyletron();
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([] as any[]);
  const [total, setTotal] = useState(0);

  const axios = new Axios().getInstance();

  const subCategoryWrapper = css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    overflow: 'auto',
    minWidth: '900px',
  });
  const labelsWrapper = css({
    display: 'grid',
    gridTemplateColumns: '0.6fr 2fr 2fr 1fr 0.8fr 0.6fr 0.8fr',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 6px',

    backgroundColor: '#063360',
    color: 'white',
    fontWeight: 700,
    fontSize: '16px',
  });

  const itemWrapper = css({
    display: 'grid',
    gridTemplateColumns: '0.6fr 2fr 2fr 1fr 0.8fr 0.6fr 0.8fr',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 6px',
  });

  const loadingCtn = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  });

  const fetchSimilarSupplierProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/supplierProduct/similar/${
          requisition.vendorProductId.id || requisition.vendorProductId._id
        }`
      );
      if (response?.status === 200) {
        //we need to modify our requisition object to have same structure as the data we get from the api
        //so that we can use the same component to display the items
        const newRequisition = {
          ...requisition,
          packagingUnit: requisition?.vendorProductId?.productPackagingUnit,
          priceList: {
            priceWoVAT: requisition?.priceWoVAT,
            vendorId: requisition?.vendorId,
          },
          productPackagingUnit: requisition?.vendorPackagingUnit,
          productCategory: {
            name: requisition?.categoryName,
          },
          supplierProductCode:
            requisition?.vendorProductId?.supplierProductCode,
          supplierProductName:
            requisition?.vendorProductId?.supplierProductName,
          _id: requisition?.vendorProductId?.id,
        };
        const fetchedItems = response?.data?.data;
        const filteredItems = fetchedItems.filter(
          (item: any) =>
            item._id !== requisition.vendorProductId._id ||
            item.packagingUnit._id !== requisition.vendorPackagingUnit ||
            item.priceList.vendorId._id !== requisition.vendorId._id
        );
        const newItems = [newRequisition, ...filteredItems];
        setItems(newItems);
      }
      setIsLoading(false);
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (requisition) {
      fetchSimilarSupplierProducts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisition]);

  const splitItemHandler = async () => {
    //items which have quantity input
    const selectedItems = items.filter((item: any) => item.quantity);
    //we need to check Total Qty x Total Conversion Rate = SUM OF ( Line Level Qty x Line Level Conversion Rate )
    //if the total quantity of the requisition line is equal to the sum of the quantity of the selected items
    //then we can proceed to split the requisition line
    const totalQuantity = requisition?.quantity;
    const totalConversionRate =
      requisition?.vendorProductId?.productPackagingUnit?.conversionRate;
    const sumOfQuantity = selectedItems.reduce(
      (acc: number, item: any) =>
        acc + item?.quantity * item?.packagingUnit?.conversionRate,
      0
    );
    if (totalQuantity * totalConversionRate !== sumOfQuantity) {
      toaster.warning(
        'The total should be equal to the sum of the quantity of the selected items',
        {
          autoHideDuration: 3000,
        }
      );
      return;
    }
    //if total is less than or equal to the sum of the quantity of the selected items
    //then we can proceed to split the requisition line
    const newItems = selectedItems.map((item: any) => {
      return {
        ...requisition,
        categoryName: item?.productCategory?.name,
        priceWoVAT: item?.priceList?.priceWoVAT,
        quantity: item?.quantity,
        vendorId: item?.priceList?.vendorId,
        vendorPackagingUnit: item?.packagingUnit,
        vendorProductId: {
          id: item?._id,
          label: `${item?.supplierProductCode}-${item?.supplierProductName}-${item?.packagingUnit?.name}`,
          categoryName: item?.productCategory?.name,
          productPackagingUnit: item?.packagingUnit,
          supplierProductCode: item?.supplierProductCode,
          supplierProductName: item?.supplierProductName,
        },
      };
    });
    onSplit(newItems);
  };

  useEffect(
    () => {
      if (items.length > 0) {
        const sumOfQuantity = items.reduce(
          (acc: number, item: any) =>
            acc +
            (item?.quantity || 0) *
              (item?.packagingUnit?.conversionRate ||
                item?.vendorProductId?.productPackagingUnit?.conversionRate),
          0
        );
        setTotal(sumOfQuantity);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          },
        },
        Dialog: {
          style: {
            width: '80vw',
            maxHeight: '85vh',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          },
        },
      }}
    >
      <ModalHeader>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '4px',
          })}
        >
          <h3 style={{ margin: 0 }}>Split items to different suppliers</h3>
          <p
            style={{
              margin: 0,
              fontWeight: 'semi-bold',
              textAlign: 'right',
              fontSize: '16px',
            }}
          >
            Original Quantity: {requisition?.quantity}{' '}
            {requisition?.vendorProductId?.productPackagingUnit?.name} ={' '}
            {requisition?.quantity *
              requisition?.vendorProductId?.productPackagingUnit
                ?.conversionRate}{' '}
            {
              requisition?.vendorProductId?.productPackagingUnit?.measureUnitId
                ?.name
            }
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        {!isLoading && items.length > 0 && (
          <div className={subCategoryWrapper}>
            <div className={labelsWrapper}>
              <span>Product Code</span>
              <span>Product Name</span>
              <span>Supplier</span>
              <span>Packaging Unit</span>
              <span>Price w/o VAT</span>
              <span>Quantity</span>
              <span
                style={{
                  textAlign: 'right',
                }}
              >
                Base Qty
              </span>
            </div>
            {items.map((item: any) => (
              <div key={item?._id} className={itemWrapper}>
                <span>{item?.supplierProductCode}</span>
                <span>{item?.supplierProductName}</span>
                <span>{item?.priceList?.vendorId?.name}</span>
                <span>{item?.packagingUnit?.name}</span>
                <span>{numberWithCommas(item?.priceList?.priceWoVAT)}</span>
                <span>
                  <Input
                    positive
                    onChange={(e: any) => {
                      const value = e.target.value;
                      const newItems = [...items];
                      const index = newItems.findIndex(
                        (i: any) =>
                          i._id === item._id &&
                          i.packagingUnit._id === item.packagingUnit._id &&
                          i.priceList.vendorId._id ===
                            item.priceList.vendorId._id
                      );
                      newItems[index].quantity = value;
                      setItems(newItems);
                    }}
                    placeholder="Quantity"
                    clearOnEscape
                    type="number"
                    name="quantity"
                    id="quantity"
                    value={item?.quantity || ''}
                    size={SIZE.compact}
                    overrides={{
                      Input: {
                        style: ({ $theme }: any) => ({
                          padding: '4px 12px',
                          fontSize: '14px',
                        }),
                      },
                    }}
                  />
                </span>
                <span
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {item?.quantity
                    ? numberWithCommas(
                        item?.quantity * item?.packagingUnit?.conversionRate
                      )
                    : '-'}
                </span>
              </div>
            ))}
            <div>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'right',
                  marginTop: '8px',
                }}
              >
                Total: {numberWithCommas(total)}
              </p>
            </div>
          </div>
        )}
        {!isLoading && items.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <h3>No similar products found</h3>
          </div>
        )}
        {isLoading && (
          <div className={loadingCtn}>
            <Spinner />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button kind={KIND.tertiary} onClick={onClose}>
          Back
        </Button>
        <Button
          onClick={splitItemHandler}
          //button is disabled if there is no quantity input to any one of the items, if there is, then the button is enabled
          disabled={items.filter((item: any) => item.quantity).length === 0}
        >
          Split
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SplitItemsModal;
