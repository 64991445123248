import classNames from 'classnames';
import './index.scss';

interface IProps {
  reportHeader: Array<any>;
  reportData: Array<Array<any>>;
  type?: string;
}

const ReportList = ({ reportHeader, reportData, type }: IProps) => {
  return (
    <div className="report-list">
      <div className="report-list-header">
        {reportHeader.map((item, index) => (
          <div
            key={`header_${index}`}
            className={classNames({
              'report-list-header-columnNumber': index === 10,
              'report-list-header-column': !(index === 10),
            })}
          >
            <div
              className="report-list-header-column__label"
              style={
                index === 2 && type === 'delivery' ? { marginLeft: '70px' } : {}
              }
            >
              {item}
            </div>
          </div>
        ))}
      </div>
      <div className="report-list-rows">
        {reportData.map((item, i) => (
          <div key={`data_${i}`} className="report-list-row">
            {item.map((_item, j) => (
              <div
                key={`data_${i}_${j}`}
                className={classNames({
                  'report-list-row-columnNumber': j === 10,
                  'report-list-row-column': !(j === 10),
                })}
                style={j === 1 ? { paddingLeft: '-30px' } : {}}
              >
                <div
                  className="report-list-row-column__label"
                  // style={
                  //   j === 1 && type === 'delivery'
                  //     ? { flex: '1', width: '250px' }
                  //     : j === 1 && type === 'order'
                  //     ? { width: '240px' }
                  //     : j === 6 && type === 'invoice'
                  //     ? { width: '255px' }
                  //     : {}
                  // }
                >
                  {_item}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportList;
