import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Input } from 'baseui/input';
import colors from 'constants/colors';

import classes from './style.module.css';
import { Select } from 'baseui/select';
import { useStyletron } from 'styletron-react';
import { Axios } from 'core/services/http';
import { toaster } from 'baseui/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker } from 'baseui/datepicker';
import { Spinner } from 'baseui/spinner';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

const SORT_OPTIONS = [
  {
    id: 1,
    label: 'Quantity Added',
    value: 'quantityAdded',
  },
  {
    id: 2,
    label: 'Quantity Remaining',
    value: 'quantityRemaining',
  },
];

const RequisitionLine = ({
  user,
  onChange,
  data,
  vendorProductOptions,
  onChangeVendorProductId,
  onChangeVendorId,
  onDelete,
  requisition,
}: any) => {
  const [css] = useStyletron();

  const axios = new Axios().getInstance();
  const [vendorOptions, setVendorOptions] = useState([]);
  const [vendor, setVendor] = useState<any>(null);

  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.red,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
    ':disabled': {
      backgroundColor: colors.mediumGray,
      cursor: 'not-allowed',
    },
  });

  const getVendorForProduct = async () => {
    const vendorProductId = data.vendorProductId.id;
    const requisitionId = requisition.id;
    try {
      const response = await axios.get(
        `/requisition/pricelists/${requisitionId}/${vendorProductId}`
      );
      if (response?.data?.success) {
        console.log(response.data.data);
        const priceLists = response.data.data;
        const vendorOptions = priceLists.map((priceList: any) => ({
          id: priceList.vendorId._id,
          label: priceList.vendorId.name,
          priceWoVAT: priceList.priceWoVAT,
        }));
        console.log('vendorOptions', vendorOptions);
        setVendorOptions(vendorOptions);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  useEffect(() => {
    if (data.vendorProductId.id && data.vendorProductId.id !== '') {
      console.log('data.vendorProductId.id', data.vendorProductId.id);
      getVendorForProduct();
    }
    if (data.vendorProductId.id === '') {
      setVendorOptions([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.vendorProductId]);

  // find the vendor from the vendorOptions array
  useEffect(() => {
    const selectedVendor = vendorOptions.find(
      (vendor: any) =>
        vendor.id === data.vendorId.id || vendor.id === data.vendorId._id
    );
    setVendor(selectedVendor);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorOptions, data.vendorId]);

  return (
    <>
      <div className={classes.lineInputContainer}>
        <div className={classes.input}>
          <Select
            placeholder="Enter Vendor Product Id"
            options={vendorProductOptions}
            value={
              Array.isArray(data?.vendorProductId)
                ? data?.vendorProductId
                : [data?.vendorProductId]
            }
            onChange={(e) => {
              localStorage.setItem('unsaved', 'true');
              onChangeVendorProductId(e, data._id);
            }}
            disabled={!user?.manualAddInPR}
          />
        </div>
        <div className={classes.input}>
          <Select
            placeholder="Enter Vendor"
            options={vendorOptions}
            value={vendor ? [vendor] : []}
            onChange={(e) => {
              localStorage.setItem('unsaved', 'true');
              onChangeVendorId(e, data._id);
            }}
            disabled={!user?.manualAddInPR}
          />
        </div>
        <div className={classes.input}>
          <Input
            type="number"
            placeholder="Enter Quantity"
            value={data.quantity}
            onChange={(e) => {
              localStorage.setItem('unsaved', 'true');
              onChange(e, 'quantity', data._id);
            }}
          />
        </div>
        <div className={classes.input}>
          <Input
            type="number"
            placeholder="PriceWoVAT"
            value={data?.vendorId?.priceWoVAT || data?.priceWoVAT}
            readOnly
          />
        </div>
        <div className={classes.input}>
          <Input
            type="number"
            placeholder="Total Amount"
            value={
              data.quantity * (data?.vendorId?.priceWoVAT || data?.priceWoVAT)
            }
            readOnly
          />
        </div>
        <div className={classes.input}>
          <Input
            type="date"
            placeholder="Supply Date"
            value={
              data.supplyDate
                ? new Date(data.supplyDate).toISOString().split('T')[0]
                : new Date(requisition.SupplyDate).toISOString().split('T')[0]
            }
            onChange={(e) => {
              localStorage.setItem('unsaved', 'true');
              onChange(e, 'supplyDate', data._id);
            }}
          />
        </div>
        <div className={classes.deleteCtn}>
          <button
            className={buttonStyle}
            onClick={() => {
              onDelete(data._id);
            }}
          >
            <DeleteIcon width={20} height={20} className={classes.icon} />
          </button>
        </div>
      </div>
    </>
  );
};

interface IRequisitionLine {
  _id: string;
  vendorProductId: {
    label: string;
    id: string;
  };
  quantity: string;
  supplyDate: string;
  vendorId: {
    label: string;
    id: string;
  };
}

const ProcessRequisitionOld = () => {
  const user = JSON.parse(localStorage.getItem('auth-user') || '{}');

  const { id } = useParams();

  const [templateOptions, setTemplateOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [storesFetched, setStoresFetched] = useState(false);
  const [vendorProductsFetched, setVendorProductsFetched] = useState(false);
  const [vendorProductOptions, setVendorProductOptions] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [supplyDate, setSupplyDate] = useState<Date | null>(null);
  const [requisition, setRequisition] = useState<any>(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOption, setSortOption] = useState([SORT_OPTIONS[0]]);

  const [requisitionLines, setRequisitionLines] = useState<IRequisitionLine[]>(
    []
  );

  const [initialStepDone, setInitialStepDone] = useState(false);

  const [css] = useStyletron();

  const axios = new Axios().getInstance();
  const navigate = useNavigate();

  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    minWidth: '140px',
    ':disabled': {
      backgroundColor: colors.mediumGray,
      cursor: 'not-allowed',
    },
  });

  const getStores = async () => {
    try {
      setStoresFetched(false);
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        const fetchedStores = response.data.data;
        const storeOptions = fetchedStores.map((store: any) => ({
          label: store.name,
          id: store.id,
        }));
        setStoreOptions(storeOptions);
        setStoresFetched(true);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      setStoresFetched(true);
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getTemplates = async () => {
    try {
      const response = await axios.get('/requisition-template');
      if (response?.data?.success) {
        const fetchedTemplates = response.data.data;
        console.log(fetchedTemplates);
        console.log(selectedStore[0]);
        const liveTemplates = fetchedTemplates.filter(
          (template: any) => template.status === 'Live'
        );
        // now filter the templates based on the selected store, if the templateStoreList contains the selected store then only show that template, and if the validForAllStores is true then show that template
        const filteredTemplates = liveTemplates.filter(
          (template: any) =>
            template.validForAllStores ||
            template.includedStoreList.some(
              // @ts-ignore
              (storeId: any) => storeId === selectedStore[0].id
            )
        );
        const templateOptions = filteredTemplates.map((template: any) => ({
          label: template.templateName,
          id: template.id,
        }));
        const BlankTemplate = {
          label: 'Blank Template',
          id: 'blank',
        };
        if (user?.createFromBlankTemplate === true) {
          templateOptions.unshift(BlankTemplate);
        }
        setTemplateOptions(templateOptions);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getVendorProducts = async () => {
    setVendorProductsFetched(false);
    try {
      const response = await axios.get('/supplierProducts', {
        params: {
          all: true,
        },
      });
      if (response?.data?.success) {
        const fetchedVendorProducts = response.data.data?.products;
        const vendorProductOptions = fetchedVendorProducts.map(
          (vendorProduct: any) => ({
            label: `${vendorProduct?.supplierProductCode}-${vendorProduct?.supplierProductName}-${vendorProduct?.packagingUnit?.name}`,
            id: vendorProduct.id,
          })
        );
        setVendorProductOptions(vendorProductOptions);

        setVendorProductsFetched(true);
      }
    } catch (error: any) {
      setVendorProductsFetched(true);
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const onChangeTemplate = (params: any) => {
    setSelectedTemplate(params.value);
  };

  const onChangeStore = (params: any) => {
    setSelectedStore(params.value);
  };

  const getTemplate = async (templateId: string) => {
    try {
      const response = await axios.get(`/requisition-template/${templateId}`);
      if (response?.data?.success) {
        const fetchedTemplate = response.data.data;
        return fetchedTemplate;
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const CreateRequisitionHandler = async () => {
    setInitialStepDone(true);
    // @ts-ignore
    const templateId = selectedTemplate[0].id;
    if (templateId !== 'blank') {
      const template = await getTemplate(templateId);
      const requisitionLines = template.templateLines.map(
        (templateLine: any) => ({
          _id: uuidv4(),
          vendorProductId: {
            label: templateLine.vendorProductId.supplierProductName,
            id: templateLine.vendorProductId._id,
          },
          quantity: '',
        })
      );
      setRequisitionLines(requisitionLines);
    } else {
      const requisitionLines = [
        {
          _id: uuidv4(),
          vendorProductId: {
            label: '',
            id: '',
          },
          quantity: '',
          supplyDate: '',
          vendorId: {
            label: '',
            id: '',
          },
        },
      ];
      setRequisitionLines(requisitionLines);
    }
  };

  const requisitionLineChangeHandler = (e: any, field: string, id: string) => {
    const updatedRequisitionLines = requisitionLines.map((line: any) => {
      if (line._id === id) {
        return {
          ...line,
          [field]: e.target.value,
        };
      }
      return line;
    });
    // @ts-ignore
    setRequisitionLines(updatedRequisitionLines);
  };

  const vendorProductIdChangeHandler = (params: any, id: string) => {
    const updatedRequisitionLines = requisitionLines.map((requisitionLine) => {
      if (requisitionLine._id === id) {
        return { ...requisitionLine, vendorProductId: params.value[0] };
      }
      return requisitionLine;
    });
    setRequisitionLines(updatedRequisitionLines);
  };

  const vendorIdChangeHandler = (params: any, id: string) => {
    console.log(params);
    const updatedRequisitionLines = requisitionLines.map((requisitionLine) => {
      if (requisitionLine._id === id) {
        return { ...requisitionLine, vendorId: params.value[0] };
      }
      return requisitionLine;
    });
    setRequisitionLines(updatedRequisitionLines);
  };

  const addNewRequisitionLine = () => {
    const newRequisitionLine = {
      _id: uuidv4(),
      vendorProductId: {
        label: '',
        id: '',
      },
      quantity: '',
      supplyDate: requisition.SupplyDate,
      vendorId: {
        label: '',
        id: '',
      },
    };
    setRequisitionLines([newRequisitionLine, ...requisitionLines]);
  };

  const deleteRequisitionLineHandler = (id: string) => {
    console.log(id);
    const updatedTemplateLines = requisitionLines.filter(
      // @ts-ignore
      (templateLine) => templateLine._id !== id
    );
    setRequisitionLines(updatedTemplateLines);
  };

  const saveRequisitionHandler = async () => {
    const updatedRequisitionLines = requisitionLines.map((requisitionLine) => ({
      // @ts-ignore
      vendorProductId: requisitionLine.vendorProductId.id,
      quantity: requisitionLine.quantity,
      vendorId: requisitionLine.vendorId.id || requisitionLine.vendorId,
      priceWoVAT:
        // @ts-ignore
        requisitionLine.vendorId.priceWoVAT || requisitionLine.priceWoVAT,
      supplyDate: requisitionLine.supplyDate || requisition.SupplyDate,
    }));
    const data = {
      // @ts-ignore
      storeId: selectedStore[0]?.id,
      requisitionLines: updatedRequisitionLines,
      // @ts-ignore
      templateUsed: selectedTemplate[0]?.id,
      SupplyDate: supplyDate,
      companyId: requisition?.companyId?._id,
      requisitionId: requisition?.id,
      process: true,
    };
    try {
      const response = await axios.patch('/requisition/update', data);
      if (response?.data?.success) {
        console.log(response.data.data);
        toaster.positive('Requisition updated successfully', {
          autoHideDuration: 4000,
        });
        navigate('/requisitions');
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          toaster.negative(message, {
            autoHideDuration: 4000,
          });
        });
      }
    }
  };

  const getRequisition = async () => {
    try {
      const response = await axios.get(`/requisition/${id}`);
      if (response?.data?.success) {
        const fetchedRequisition = response.data.data;
        setRequisition(fetchedRequisition);
        setTotalAmount(fetchedRequisition?.totalAmount || 0);
        // @ts-ignore
        setSelectedStore([
          // @ts-ignore
          {
            label: fetchedRequisition.storeId.name,
            id: fetchedRequisition.storeId._id,
          },
        ]);
        // @ts-ignore
        setSelectedTemplate([
          // @ts-ignore
          {
            label: fetchedRequisition?.templateUsed?.name,
            id: fetchedRequisition?.templateUsed?._id,
          },
        ]);
        setInitialStepDone(true);
        const formattedSupplyDate = fetchedRequisition.SupplyDate
          ? new Date(fetchedRequisition.SupplyDate)
          : new Date();
        setSupplyDate(formattedSupplyDate);
        console.log('reqs', fetchedRequisition);
        console.log('req lines', fetchedRequisition.requisitionLines);
        const fetchedRequisitionLines = fetchedRequisition.requisitionLines.map(
          (requisitionLine: any) => {
            const vendorProductOption = vendorProductOptions.find(
              (vendorProductOption) =>
                // @ts-ignore
                vendorProductOption.id === requisitionLine.vendorProductId._id
            );
            return {
              ...requisitionLine,
              vendorProductId: vendorProductOption,
            };
          }
        );
        if (fetchedRequisitionLines && fetchedRequisitionLines.length > 0) {
          // sort the requisition lines by the quantity
          fetchedRequisitionLines.sort((a: any, b: any) => {
            if (a.quantity > b.quantity) {
              return -1;
            }
            if (a.quantity < b.quantity) {
              return 1;
            }
            return 0;
          });
        }
        setRequisitionLines(
          fetchedRequisitionLines ? fetchedRequisitionLines : []
        );
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        console.log(errors);
      }
    }
  };

  useEffect(() => {
    if (id && storesFetched && vendorProductsFetched) {
      getRequisition();
      setIsLoading(false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, storesFetched, vendorProductsFetched]);

  useEffect(() => {
    getStores();
    getVendorProducts();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStore) {
      getTemplates();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore]);

  const onChangeSortOption = (params: any) => {
    setSortOption(params.value);
    const sortValue = params.value[0].value;
    // sort the requisition lines based on the sort option selected using quantity
    requisitionLines.sort((a, b) => {
      if (sortValue === 'quantityRemaining') {
        // @ts-ignore
        return a.quantity - b.quantity;
      }
      // @ts-ignore
      return b.quantity - a.quantity;
    });
  };

  const convertToPOHandler = async () => {
    try {
      const response = await axios.patch(`/requisition/convert-to-order/${id}`);
      if (response?.data?.success) {
        toaster.positive('Requisition converted to PO successfully', {
          autoHideDuration: 3000,
        });
        return response.data.data;
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      toaster.negative('Something went wrong', {
        autoHideDuration: 3000,
      });
      if (errors?.length) {
        console.error(errors);
      }
    }
  };

  const releaseOrderHandler = async (orderIds: any) => {
    try {
      const response = await axios.patch(`/orders/release`, {
        orderIds,
      });
      if (response?.data?.success) {
        console.log(response.data.data);
        toaster.positive(response.data.message, {
          autoHideDuration: 3000,
        });
        navigate('/requisitions');
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      toaster.negative('Something went wrong in releasing the orders', {
        autoHideDuration: 3000,
      });
      if (errors?.length) {
        console.error(errors);
      }
    }
  };

  const ProcessRequisitionHandler = async () => {
    // first save the requisition
    await saveRequisitionHandler();
    // then convert it to PO
    const orderIds = await convertToPOHandler();
    // then release the orders
    await releaseOrderHandler(orderIds);
  };

  // we need to update the total amount when the requisition lines change
  useEffect(() => {
    let totalAmount = 0;
    requisitionLines.forEach((requisitionLine) => {
      const priceWoVAT =
        // @ts-ignore
        requisitionLine?.priceWoVAT || requisitionLine?.vendorId?.priceWoVAT;
      // @ts-ignore
      if (priceWoVAT && requisitionLine?.quantity) {
        // @ts-ignore
        totalAmount += priceWoVAT * requisitionLine?.quantity;
      }
    });
    setTotalAmount(totalAmount);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisitionLines]);

  return (
    <>
      {!isLoading && (
        <div className={classes.createRequisition}>
          <div className={classes.heading}>
            <h1>Process Requisition</h1>
            <div className={classes.btns}>
              <button onClick={saveRequisitionHandler} className={buttonStyle}>
                Save
              </button>
              {requisition?.status === 'Submitted' && (
                <button
                  className={buttonStyle}
                  onClick={ProcessRequisitionHandler}
                >
                  Convert To PO
                </button>
              )}
            </div>
          </div>
          <div>
            {!initialStepDone && <h3>Please choose a template</h3>}
            <div className={classes.inputContainer}>
              <div className={classes.select}>
                <label htmlFor="">Store:</label>
                <Select
                  options={storeOptions}
                  value={selectedStore}
                  onChange={onChangeStore}
                  placeholder="Select a store"
                  disabled={initialStepDone}
                />
              </div>
              <div className={classes.select}>
                <label htmlFor="">Template:</label>
                <Select
                  options={templateOptions}
                  value={selectedTemplate}
                  onChange={onChangeTemplate}
                  placeholder="Select a template"
                  disabled={initialStepDone}
                />
              </div>
              <div className={classes.select}>
                <label htmlFor="">Requisition Code:</label>
                <Input value={requisition?.requisitionCode} readOnly />
              </div>
              {initialStepDone && (
                <div className={classes.select}>
                  <label htmlFor="supplyDate">Supply Date:</label>
                  <DatePicker
                    value={supplyDate}
                    // @ts-ignore
                    onChange={(date) => {
                      localStorage.setItem('unsaved', 'true');
                      console.log(date);
                      // @ts-ignore
                      setSupplyDate(date.date);
                    }}
                    formatString="dd/MM/yyyy"
                  />
                </div>
              )}
            </div>
            {!initialStepDone && (
              <div className={classes.createBtn}>
                <button
                  className={buttonStyle}
                  onClick={CreateRequisitionHandler}
                  disabled={
                    selectedStore.length === 0 || selectedTemplate.length === 0
                  }
                >
                  Create
                </button>
              </div>
            )}
          </div>
          {initialStepDone && (
            <div>
              <div className={classes.templateLines}>
                <div className={classes.templateLinesHeader}>
                  <h2>Requisition Line Items</h2>
                  <div className={classes.action}>
                    <Select
                      options={SORT_OPTIONS}
                      placeholder="Select a sort option"
                      value={sortOption}
                      onChange={onChangeSortOption}
                    />
                    <button
                      className={buttonStyle}
                      onClick={() => {
                        localStorage.setItem('unsaved', 'true');
                        addNewRequisitionLine();
                      }}
                      disabled={!user?.manualAddInPR}
                    >
                      Add Line Item
                    </button>
                  </div>
                </div>
                <div className={classes.info}>
                  <div>
                    <h3>Total: </h3>
                    <h3>{totalAmount}</h3>
                  </div>
                </div>
                <div className={classes.templateLineItems}>
                  <div className={classes.labelCtn}>
                    <div className={classes.labelItem}>Product:</div>
                    <div className={classes.labelItem}>Vendor:</div>
                    <div className={classes.labelItem}>Qty:</div>
                    <div className={classes.labelItem}>PriceWoVAT:</div>
                    <div className={classes.labelItem}>Amount:</div>
                    <div className={classes.labelItem}>Supply Date:</div>
                  </div>
                  {requisitionLines.map((line) => {
                    return (
                      <RequisitionLine
                        key={line._id}
                        data={line}
                        onChange={requisitionLineChangeHandler}
                        vendorProductOptions={vendorProductOptions}
                        onChangeVendorProductId={vendorProductIdChangeHandler}
                        onChangeVendorId={vendorIdChangeHandler}
                        onDelete={deleteRequisitionLineHandler}
                        user={user}
                        requisition={requisition}
                      />
                    );
                  })}
                </div>
              </div>
              <div className={classes.save}>
                <button
                  onClick={saveRequisitionHandler}
                  className={buttonStyle}
                >
                  Save
                </button>

                {requisition?.status === 'Submitted' && (
                  <button
                    className={buttonStyle}
                    onClick={ProcessRequisitionHandler}
                  >
                    Convert To PO
                  </button>
                )}
              </div>
              <div className={classes.info}>
                <div>
                  <h3>Total: </h3>
                  <h3>{totalAmount}</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isLoading && (
        <div className={classes.loading}>
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ProcessRequisitionOld;
