import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useLocation, useNavigate } from 'react-router-dom';

type LightboxState = {
  imageUrls: string[];
  currentImageIndex: number;
};

const LightboxComponent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as LightboxState | null;
  const imageUrls = state?.imageUrls ?? [];
  const currentImageIndex = state?.currentImageIndex ?? 0;

  const handleClose = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleMoveNext = () => {
    const nextIndex = (currentImageIndex + 1) % imageUrls.length;
    navigate('/lightbox', {
      state: { imageUrls, currentImageIndex: nextIndex },
    });
  };

  const handleMovePrev = () => {
    const prevIndex =
      (currentImageIndex + imageUrls.length - 1) % imageUrls.length;
    navigate('/lightbox', {
      state: { imageUrls, currentImageIndex: prevIndex },
    });
  };

  return (
    <Lightbox
      mainSrc={imageUrls[currentImageIndex]}
      nextSrc={imageUrls[(currentImageIndex + 1) % imageUrls.length]}
      prevSrc={
        imageUrls[(currentImageIndex + imageUrls.length - 1) % imageUrls.length]
      }
      onCloseRequest={handleClose}
      onMovePrevRequest={handleMovePrev}
      onMoveNextRequest={handleMoveNext}
    />
  );
};

export default LightboxComponent;
