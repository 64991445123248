import React, { useState } from 'react';
import { KIND } from 'baseui/button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { DatePicker } from 'baseui/datepicker';

const SupplyDateModal = ({
  isOpen,
  onClose,
  requisitionSupplyDate,
  onSupplyDateChange,
  requisitionLine,
  minDate,
}: any) => {
  const [newSupplyDate, setNewSupplyDate] = useState(
    requisitionLine?.supplyDate
      ? new Date(requisitionLine?.supplyDate)
      : requisitionSupplyDate
  );
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          },
        },
      }}
    >
      <ModalHeader>Supply Date</ModalHeader>
      <ModalBody>
        <p>Please select the new supply date</p>
        <div>
          <DatePicker
            positive
            minDate={minDate}
            value={newSupplyDate}
            onChange={
              //@ts-ignore
              ({ date }: any) => {
                // @ts-ignore
                const dateObj = new Date(date);
                //set the date to 9:00 AM
                dateObj.setHours(9, 0, 0, 0);
                setNewSupplyDate(dateObj);
              }
            }
            formatString="dd/MM/yyyy"
            overrides={{
              Popover: {
                props: {
                  overrides: {
                    Body: {
                      style: () => ({
                        zIndex: 4,
                      }),
                    },
                  },
                },
              },
              MonthYearSelectPopover: {
                props: {
                  overrides: {
                    Body: {
                      style: () => ({
                        zIndex: 5,
                      }),
                    },
                  },
                },
              },
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={KIND.tertiary} onClick={onClose}>
          Cancel
        </ModalButton>
        <ModalButton
          onClick={() => {
            onSupplyDateChange(newSupplyDate, requisitionLine?._id);
            onClose();
          }}
        >
          Confirm
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default SupplyDateModal;
