import { lazy, useContext, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { AppContext } from 'context';
import RequireAuth from '../require-auth';
import ProcessRequisition from 'modules/requisitions/components/process/ProcessRequisition';
import ProcessRequisitionOld from 'modules/requisitions/components/process-old/ProcessRequisitionOld';
import Reports from 'modules/reports';
import { ORDERS_DATE_FILTER } from 'constants/order';
import LightboxComponent from 'modules/lightbox';

const Auth = lazy(() => import('modules/auth'));
const Error = lazy(() => import('modules/error'));
const Dashboard = lazy(() => import('modules/dashboard'));
const MyAccount = lazy(() => import('modules/my-account'));
const Requisitions = lazy(() => import('modules/requisitions'));
const CreateRequisition = lazy(
  () => import('modules/requisitions/components/create/CreateRequisition')
);
const ViewOldRequisition = lazy(
  () => import('modules/requisitions/components/view-old/ViewOldRequisition')
);
const ViewRequisition = lazy(
  () => import('modules/requisitions/components/view/ViewRequisition')
);
const EditRequisition = lazy(
  () => import('modules/requisitions/components/edit/EditRequisition')
);
const Orders = lazy(() => import('modules/orders'));
const CreateOrder = lazy(
  () => import('modules/orders/components/create-order-view')
);

const EditOrder = lazy(() => import('modules/orders/components/edit'));
const ViewOrder = lazy(() => import('modules/orders/components/view'));
const ReissueOrder = lazy(
  () => import('modules/orders/components/reissueOrder/index')
);
const AmendOrder = lazy(() => import('modules/orders/components/amend'));
const ReceivingItems = lazy(
  () => import('modules/orders/components/receiving-items')
);

const ReopenOrderView = lazy(
  () => import('modules/orders/components/reopen-order-view')
);

const Invoices = lazy(() => import('modules/invoices'));
const CreateInvoice = lazy(
  () => import('modules/invoices/components/create/CreateInvoice')
);
const EditInvoice = lazy(
  () => import('modules/invoices/components/edit/EditInvoice')
);
const ViewInvoice = lazy(
  () => import('modules/invoices/components/view/ViewInvoice')
);
const AmendInvoice = lazy(
  () => import('modules/invoices/components/amend/AmendInvoice')
);
const Templates = lazy(() => import('modules/templates'));
const CreateTemplate = lazy(
  () => import('modules/templates/components/create/CreateTemplate')
);
const ViewTemplate = lazy(
  () => import('modules/templates/components/view/ViewTemplate')
);
const EditTemplate = lazy(
  () => import('modules/templates/components/edit/EditTemplate')
);
const PriceList = lazy(() => import('modules/price-list'));
const Master = lazy(() => import('modules/master'));
const Setup = lazy(() => import('modules/setup'));
const PasswordReset = lazy(() => import('modules/password-reset'));
const ConflictInvoices = lazy(() => import('modules/conflict-invoices'));
const ViewConflictInvoice = lazy(
  () => import('modules/conflict-invoices/components/view-conflict-invoice')
);
const EditConflictInvoice = lazy(
  () => import('modules/conflict-invoices/components/edit-conflict-invoice')
);

const AppRouter = () => {
  const {
    state: { token },
  } = useContext(AppContext);

  useEffect(() => {
    const sessionDateFilter = localStorage.getItem(ORDERS_DATE_FILTER);
    const parseSessionDateFilter = sessionDateFilter
      ? JSON.parse(sessionDateFilter)
      : null;
    if (sessionDateFilter) {
      const getCurrentHours = new Date().getHours();
      if (Number(parseSessionDateFilter?.expiry) < getCurrentHours) {
        localStorage.removeItem(ORDERS_DATE_FILTER);
      }
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        key="home"
        element={
          token && token !== '' ? (
            <Navigate replace to="/dashboard" />
          ) : (
            <Auth />
          )
        }
      />
      <Route path="/error" key="error" element={<Error />} />
      <Route path="/auth/:type" key="auth" element={<Auth />} />
      <Route path="/lightbox" element={<LightboxComponent />} />
      <Route
        path="/conflict-invoices"
        key="conflict-invoices"
        element={
          <RequireAuth>
            <ConflictInvoices />
          </RequireAuth>
        }
      />
      <Route
        path="/conflict-invoice/edit/:id"
        key="conflict-invoices"
        element={
          <RequireAuth>
            <EditConflictInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/conflict-invoice/view/:id"
        key="conflict-invoices"
        element={
          <RequireAuth>
            <ViewConflictInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/password-reset/:token"
        key="passwordReset"
        element={<PasswordReset />}
      />
      <Route
        path="/dashboard"
        key="dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />

      <Route
        path="/requisitions"
        key="requisitions"
        element={
          <RequireAuth>
            <Requisitions />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/create"
        key="requisition"
        element={
          <RequireAuth>
            <CreateRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/view-old/:id"
        key="requisition"
        element={
          <RequireAuth>
            <ViewOldRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/view/:id"
        key="requisition"
        element={
          <RequireAuth>
            <ViewRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/edit/:id"
        key="requisition"
        element={
          <RequireAuth>
            <EditRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/process/:id"
        key="requisition"
        element={
          <RequireAuth>
            <ProcessRequisition />
          </RequireAuth>
        }
      />
      <Route
        path="/requisitions/process-old/:id"
        key="requisition"
        element={
          <RequireAuth>
            <ProcessRequisitionOld />
          </RequireAuth>
        }
      />
      <Route
        path="/orders"
        key="orders"
        element={
          <RequireAuth>
            <Orders />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/create"
        key="create-order"
        element={
          <RequireAuth>
            <CreateOrder />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/edit/:id"
        key="orders"
        element={
          <RequireAuth>
            <EditOrder />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/view/:id"
        key="orders"
        element={
          <RequireAuth>
            <ViewOrder />
          </RequireAuth>
        }
      />
      <Route
        path="/reissue/order/:id"
        key="orders"
        element={
          <RequireAuth>
            <ReissueOrder />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/receive-items/:id"
        key="orders"
        element={
          <RequireAuth>
            <ReceivingItems />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/amend/:id"
        key="orders"
        element={
          <RequireAuth>
            <AmendOrder />
          </RequireAuth>
        }
      />

      <Route
        path="/orders/reopen/:id"
        key="orders-reopen"
        element={
          <RequireAuth>
            <ReopenOrderView />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices"
        key="invoices"
        element={
          <RequireAuth>
            <Invoices />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices/create"
        key="invoices"
        element={
          <RequireAuth>
            <CreateInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices/edit/:id"
        key="invoices"
        element={
          <RequireAuth>
            <EditInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices/amend/:id"
        key="invoices"
        element={
          <RequireAuth>
            <AmendInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices/view/:id"
        key="invoices"
        element={
          <RequireAuth>
            <ViewInvoice />
          </RequireAuth>
        }
      />
      <Route
        path="/templates"
        key="templates"
        element={
          <RequireAuth>
            <Templates />
          </RequireAuth>
        }
      />
      <Route
        path="/templates/create"
        key="template"
        element={
          <RequireAuth>
            <CreateTemplate />
          </RequireAuth>
        }
      />
      <Route
        path="/templates/view/:id"
        key="template"
        element={
          <RequireAuth>
            <ViewTemplate />
          </RequireAuth>
        }
      />
      <Route
        path="/templates/edit/:id"
        key="template"
        element={
          <RequireAuth>
            <EditTemplate />
          </RequireAuth>
        }
      />
      <Route
        path="/priceList"
        key="priceList"
        element={
          <RequireAuth>
            <PriceList />
          </RequireAuth>
        }
      />
      <Route
        path="/master/*"
        key="master"
        element={
          <RequireAuth>
            <Master />
          </RequireAuth>
        }
      />
      <Route
        path="/setup"
        key="setup"
        element={
          <RequireAuth>
            <Setup />
          </RequireAuth>
        }
      />
      <Route
        path="/my-account"
        key="my-account"
        element={
          <RequireAuth>
            <MyAccount />
          </RequireAuth>
        }
      />
      <Route
        path="/reports/*"
        key="reports"
        element={
          <RequireAuth>
            <Reports />
          </RequireAuth>
        }
      />

      <Route path="*" element={<Navigate replace to="/dashboard" />} />
    </Routes>
  );
};

export default AppRouter;
