import numberWithCommas from 'modules/shared/number-with-commas';
import './index.scss';
import { ResponsivePie } from '@nivo/pie';

interface PieChart {
  id: string;
  label: string;
  value: number;
}

const PurchaseContributionPieChart: React.FC<{
  title: string;
  colorScheme: string;
  data: PieChart[];
}> = ({ title, colorScheme, data }) => {
  return (
    <div className="pie-chart-reports">
      <ResponsivePie
        data={data}
        arcLabel={(data: any) =>
          `Qty: ${data?.value} (${data?.data?.percentage}%)`
        }
        // @ts-ignore
        colors={{ scheme: colorScheme }}
        margin={{ top: 40, right: 0, bottom: 200, left: 0 }}
        innerRadius={0}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 3]],
        }}
        enableArcLinkLabels={false}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: 'ruby',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'c',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'go',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'python',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'scala',
            },
            id: 'lines',
          },
          {
            match: {
              id: 'lisp',
            },
            id: 'lines',
          },
          {
            match: {
              id: 'elixir',
            },
            id: 'lines',
          },
          {
            match: {
              id: 'javascript',
            },
            id: 'lines',
          },
        ]}
        // @ts-ignore
        theme={{ text: { fontSize: 15, fontFamily: 'poppins-regular' } }}
        legends={[
          {
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateX: 20,
            translateY: 120,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 4,
            symbolSize: 20,
            itemDirection: 'left-to-right',
          },
        ]}
      />
    </div>
  );
};

export default PurchaseContributionPieChart;
