import React, { useEffect, useState } from 'react';
import { Button, KIND } from 'baseui/button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';
import { useStyletron } from 'styletron-react';
import numberWithCommas from 'modules/shared/number-with-commas';
import { Spinner } from 'baseui/icon';
import { Axios } from 'core/services/http';
import tickCircleIconURL from 'assets/icons/tick-circle.svg';
import roundedUnFillIconURL from 'assets/icons/rounded-un-fill.svg';

const ReplaceItemsModal = ({
  isOpen,
  onClose,
  requisition,
  onReplace,
}: any) => {
  const [css] = useStyletron();
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([] as any[]);

  const axios = new Axios().getInstance();

  const subCategoryWrapper = css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    overflow: 'auto',
    minWidth: '900px',
  });
  const labelsWrapper = css({
    display: 'grid',
    gridTemplateColumns: '0.3fr 1fr 2fr 1fr 1fr 2fr',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 6px',

    backgroundColor: '#063360',
    color: 'white',
    fontWeight: 700,
    fontSize: '16px',
  });

  const itemWrapper = css({
    display: 'grid',
    gridTemplateColumns: '0.3fr 1fr 2fr 1fr 1fr 2fr',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 6px',
  });

  const selectIcon = css({
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  });

  const circleIcon = css({
    width: '24px',
    height: '24px',
    filter:
      'invert(59%) sepia(36%) saturate(6530%) hue-rotate(133deg) brightness(94%) contrast(93%)',
  });

  const roundedIcon = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(69%) sepia(9%) saturate(10%) hue-rotate(343deg) brightness(90%) contrast(90%)',
  });

  const loadingCtn = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  });

  const fetchSimilarSupplierProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/supplierProduct/similar/${
          requisition.vendorProductId.id || requisition.vendorProductId._id
        }`
      );
      if (response?.status === 200) {
        const fetchedItems = response?.data?.data;
        const newItems = fetchedItems.map((item: any) => {
          if (
            (item._id === requisition.vendorProductId.id ||
              item._id === requisition.vendorProductId._id) &&
            item.packagingUnit._id === requisition.vendorPackagingUnit &&
            item.priceList.vendorId._id === requisition.vendorId._id
          ) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
          return item;
        });
        setItems(newItems);
      }
      setIsLoading(false);
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (requisition) {
      fetchSimilarSupplierProducts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisition]);

  const selectItemHandler = (
    id: string,
    packagingUnitId: string,
    vendorId: string
  ) => {
    //we need to set only the selected item to true and the rest to false
    const newItems = items.map((item: any) => {
      if (
        item._id === id &&
        item.packagingUnit._id === packagingUnitId &&
        item.priceList.vendorId._id === vendorId
      ) {
        item.isSelected = !item.isSelected;
      } else {
        item.isSelected = false;
      }
      return item;
    });
    //@ts-ignore
    setItems(newItems);
  };

  const replaceItemHandler = async () => {
    const selectedItem = items.find((item: any) => item.isSelected);
    if (selectedItem) {
      const newQuantity =
        (requisition?.quantity *
          requisition?.vendorProductId?.productPackagingUnit?.conversionRate) /
        selectedItem?.packagingUnit?.conversionRate;
      const newRequisitionLine = {
        ...requisition,
        categoryName: selectedItem?.productCategory?.name,
        priceWoVAT: selectedItem?.priceList?.priceWoVAT,
        quantity: newQuantity,
        vendorId: selectedItem?.priceList?.vendorId,
        vendorPackagingUnit: selectedItem?.productPackagingUnit,
        vendorProductId: {
          id: selectedItem?._id,
          supplierProductCode: selectedItem?.supplierProductCode,
          supplierProductName: selectedItem?.supplierProductName,
          label: `${selectedItem?.supplierProductCode}-${selectedItem?.supplierProductName}-${selectedItem?.packagingUnit?.name}`,
          categoryName: selectedItem?.productCategory?.name,
          productPackagingUnit: selectedItem?.packagingUnit,
        },
      };
      onReplace(newRequisitionLine);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          },
        },
        Dialog: {
          style: {
            width: '80vw',
            maxHeight: '85vh',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          },
        },
      }}
    >
      <ModalHeader>Replace the item with similar products</ModalHeader>
      <ModalBody>
        {!isLoading && items.length > 0 && (
          <div className={subCategoryWrapper}>
            <div className={labelsWrapper}>
              <span></span>
              <span>Product Code</span>
              <span>Product Name</span>
              <span>Packaging Unit</span>
              <span>Price w/o VAT</span>
              <span>Supplier</span>
            </div>
            {items.map((item: any) => (
              <div key={item?._id} className={itemWrapper}>
                <div>
                  <button
                    className={selectIcon}
                    onClick={() =>
                      selectItemHandler(
                        item?._id,
                        item?.packagingUnit?._id,
                        item?.priceList?.vendorId?._id
                      )
                    }
                  >
                    {item?.isSelected ? (
                      <img
                        src={tickCircleIconURL}
                        alt="Select"
                        className={circleIcon}
                      />
                    ) : (
                      <img
                        src={roundedUnFillIconURL}
                        alt="Select"
                        className={roundedIcon}
                      />
                    )}
                  </button>
                </div>
                <span>{item?.supplierProductCode}</span>
                <span>{item?.supplierProductName}</span>
                <span>{item?.packagingUnit?.name}</span>
                <span>{numberWithCommas(item?.priceList?.priceWoVAT)}</span>
                <span>{item?.priceList?.vendorId?.name}</span>
              </div>
            ))}
          </div>
        )}
        {!isLoading && items.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <h3>No similar products found</h3>
          </div>
        )}
        {isLoading && (
          <div className={loadingCtn}>
            <Spinner />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button kind={KIND.tertiary} onClick={onClose}>
          Back
        </Button>
        <Button
          disabled={items.filter((item: any) => item.isSelected).length === 0}
          onClick={replaceItemHandler}
        >
          Replace
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReplaceItemsModal;
