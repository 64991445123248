import { Route, Routes } from 'react-router-dom';
import { useStyletron } from 'styletron-react';
import ReportsList from './components/reports';
import OrderListReport from './components/order-list-report';
import RequisitionListReport from './components/requisitionListReport';
import InvoiceListReport from './components/invoice-list-report';
import PurchaseContributionReport from './components/purchase-contribution-report';
import InvoiceTodayListReport from './components/invoice-list-today-report';
import OrderListTodayListReport from './components/order-list-today-report';
import DailyReceivingInvoiceReport from './components/daily-receiving-report';

const Reports = () => {
  const [css] = useStyletron();
  return (
    <div className={css({ marginTop: '70px' })}>
      <Routes>
        <Route path="/" key="reports" element={<ReportsList />} />
        <Route
          path="/orderListReport"
          key="orderListReport"
          element={<OrderListReport />}
        />
        <Route
          path="/requisitionListReport"
          key="requisitionListReport"
          element={<RequisitionListReport />}
        />
        <Route
          path="/invoiceListReport"
          key="invoiceListReport"
          element={<InvoiceListReport />}
        />
        <Route
          path="/purchaseContributionReport"
          key="purchaseContributionReport"
          element={<PurchaseContributionReport />}
        />
        <Route
          path="/invoiceTodayListReport"
          key="invoiceTodayListRepor"
          element={<InvoiceTodayListReport />}
        />
        <Route
          path="/deliverTodayReport"
          key="deliverTodayReport"
          element={<OrderListTodayListReport />}
        />
        <Route
          path="/dailyRecievingInvoiceReport"
          key="dailyRecievingInvoiceReport"
          element={<DailyReceivingInvoiceReport />}
        />
      </Routes>
    </div>
  );
};

export default Reports;
