import React, { useContext, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Button, SHAPE as BUTTON_SHAPE, SIZE } from 'baseui/button';

import { AppContext } from 'context';
import Logo from '../../logo';
import bell from '../../../../../assets/icons/notification.png';
import UserProfile from './user-profile';
import colors from 'constants/colors';
import Notifications from './notifications';
import NotificationListener from './notification-listener';
import { Axios } from 'core/services/http';
import { event } from 'core/events';
import { REFETCH_NOTIFICATION_LIST } from 'constants/events';
import OutsideAlerter from '../../outside-alerter';

const Toolbar = () => {
  const {
    state: { user },
  } = useContext(AppContext);
  const [active, setActive] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState<Array<any>>([]);
  const [css] = useStyletron();
  const axios = new Axios().getInstance();

  const parentWrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100vw',
    position: 'fixed',
    top: '0',
    left: '0',
    padding: '14px 20px',
    zIndex: 2,
    background: colors.primaryBlue,
    boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)',
    '@media (max-width: 600px)': {
      padding: '14px 10px',
    },
    '@media (max-width: 500px)': {
      zIndex: 1000,
    },
  });
  const bellWrapper = css({
    width: '26px',
    cursor: 'pointer',
    transform: 'rotate(28deg)',
    borderRadius: '50%',
    padding: '6px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: 'red',
    },
  });
  const notification = css({
    width: '100%',
    filter: 'brightness(0) invert(1)',
  });
  const activeNotification = css({
    width: '26px',
    cursor: 'pointer',
    transform: 'rotate(28deg)',
    borderRadius: '50%',
    padding: '6px',
    backgroundColor: 'rgb(0, 0, 0,0.4)',
  });
  const notificationCount = css({
    width: '16px',
    height: '16px',
    fontSize: '12px',
    top: '0px',
    position: 'absolute',
    zIndex: 1,
    left: '16px',
    transform: 'rotate(-28deg)',
    backgroundColor: colors.lightGreen,
    color: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '500',
    borderRadius: '50%',
  });

  const resolveButtonText = (): string => {
    if (user?.firstName && user?.lastName) {
      return (
        user.firstName.substring(0, 1) + user.lastName.substring(0, 1)
      ).toUpperCase();
    }
    if (user?.firstName) {
      return user.firstName.substring(0, 2).toUpperCase();
    }
    return '';
  };

  const getNotifications = async () => {
    try {
      const response = await axios.get('/notifications/list');
      if (response?.data?.success) {
        setNotifications(response.data.data.notifications);
        setUnreadCount(response.data.data.unreadCount);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          console.log(message);
        });
      }
    }
  };

  useEffect(() => {
    getNotifications();
    event.subscribe(REFETCH_NOTIFICATION_LIST, getNotifications);

    return () => event.unsubscribe(REFETCH_NOTIFICATION_LIST, getNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className={parentWrapperStyles}>
        <div className={css({ display: 'flex', alignItems: 'center' })}>
          <div>
            <Logo height={40} clicked={() => {}} />
          </div>
        </div>
        <div
          className={css({
            display: 'flex',
            marginLeft: 'auto',
            marginRight: '24px',
            gap: '15px',
            alignItems: 'center',
          })}
        >
          <UserProfile>
            <p
              className={css({
                margin: '0px 16px',
                color: colors.white,
                '@media (max-width: 540px)': {
                  display: 'none',
                },
              })}
            >
              {user?.email}
            </p>
          </UserProfile>
          <div className={!active ? bellWrapper : activeNotification}>
            {unreadCount > 0 ? (
              <span className={notificationCount}>{unreadCount}</span>
            ) : null}
            <img
              src={bell}
              alt={'nofitaction'}
              onClick={() => setActive(!active)}
              className={notification}
            />
          </div>
          <UserProfile>
            <Button shape={BUTTON_SHAPE.circle} size={SIZE.compact}>
              {resolveButtonText()}
            </Button>
          </UserProfile>
          <OutsideAlerter
            outsideClicked={(outsideClicked: boolean) => {
              if (outsideClicked) {
                setActive(false);
              }
            }}
          >
            {active && (
              <Notifications
                notificationData={notifications}
                refetch={getNotifications}
              />
            )}
          </OutsideAlerter>

          <NotificationListener refetch={getNotifications} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Toolbar;
