import React, { useEffect } from 'react';
import { MultiSelect, Select } from 'baseui/select';
import { DatePicker } from 'baseui/datepicker';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { useStyletron } from 'styletron-react';
import colors from 'constants/colors';
import './index.scss';
import { timeCheck } from 'modules/shared/time-check';

interface IProps {
  dates: Array<any>;
  startDate: Array<any>;
  endDate: Array<any>;
  dateOption: any;
  setStartDate: any;
  setEndDate: any;
  setDateOption: any;
  onSubmit: any;
  vendors: any;
  handleVendorChange: any;
  vendorsValue: any;
  products: any;
  productsValue: any;
  handleProductChange: any;
  handleClear: any;
  isDateDisable: any;
  setIsDateDisable: any;
}

const FilterList = ({
  dates,
  startDate,
  endDate,
  dateOption,
  setStartDate,
  setEndDate,
  setDateOption,
  onSubmit,
  vendors,
  handleVendorChange,
  vendorsValue,
  products,
  productsValue,
  handleProductChange,
  handleClear,
  isDateDisable,
  setIsDateDisable,
}: IProps) => {
  const [css] = useStyletron();
  const datesOptions = dates.map(({ id, label }) => ({ label, id }));

  const buttonStyleClear = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: '#FF0000',
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });
  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });

  const setDateInInput = (value: any) => {
    const date = timeCheck(value[0]?.id);
    setStartDate([date.startTime]);
    setEndDate([date.endTime]);
  };

  useEffect(() => {
    //when the component loads we need to setDateOption to this week
    const thisWeekOption = datesOptions.find(
      (option: any) => option.id === 'this_week'
    );
    setDateOption([thisWeekOption]);
    setDateInInput([thisWeekOption]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="purchase-contribution">
      <div>
        <FlexGrid
          flexGridColumnCount={[1, 2, 5]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Date Period</div>
              <Select
                options={datesOptions}
                value={dateOption}
                placeholder="Today"
                disabled={false}
                onChange={(params: any) => {
                  setDateOption(params.value);
                  setDateInInput(params.value);
                  if (params.value[0]?.id === 'custom') {
                    setIsDateDisable(false);
                  } else setIsDateDisable(true);
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Start Date</div>
              <DatePicker
                disabled={isDateDisable}
                required={true}
                value={startDate}
                onChange={({ date }) => {
                  setStartDate(Array.isArray(date) ? date : [date]);
                }}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">End Date</div>
              <DatePicker
                disabled={isDateDisable}
                required={true}
                value={endDate}
                onChange={({ date }) =>
                  setEndDate(Array.isArray(date) ? date : [date])
                }
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Products</div>
              <MultiSelect
                options={products}
                labelKey="label"
                valueKey="id"
                value={productsValue}
                onChange={handleProductChange}
                placeholder="Select options..."
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Vendors</div>
              <MultiSelect
                options={vendors}
                labelKey="label"
                valueKey="id"
                value={vendorsValue}
                onChange={handleVendorChange}
                placeholder="Select vendors..."
              />
            </div>
          </FlexGridItem>
        </FlexGrid>
        <div className="btn-container">
          <button
            type="button"
            className={buttonStyleClear}
            onClick={handleClear}
          >
            Clear Filter
          </button>
          <button type="button" className={buttonStyle} onClick={onSubmit}>
            Apply Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterList;
