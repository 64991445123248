import './index.scss';
import { INotification } from '../../../../../interfaces/notification.interface';

import requisitionIconURL from 'assets/icons/requisition-white.svg';
import templatesIconURL from 'assets/icons/templates-white.svg';
import priceListIconURL from 'assets/icons/price-list-white.svg';
import invoicesIconURL from 'assets/icons/invoices-white.svg';
import ordersIconURL from 'assets/icons/orders-white.svg';
import { NotificationTypeEnum } from 'modules/shared/enums/notification-type.enum';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

interface INotificationCardProps extends INotification {
  markAsRead?: (id: string) => void;
}

const NotificationCard = ({
  id,
  name,
  message,
  type,
  data,
  read,
  date,
  markAsRead,
}: INotificationCardProps) => {
  const navigate = useNavigate();

  const markAsClickedHandler = () => {
    if (!markAsRead) {
      return;
    }
    markAsRead(id);
  };

  const resolveIcon = () => {
    switch (type) {
      case NotificationTypeEnum.Requisition:
        return requisitionIconURL;
      case NotificationTypeEnum.Order:
        return ordersIconURL;
      case NotificationTypeEnum.Invoice:
        return invoicesIconURL;
      case NotificationTypeEnum.PriceList:
        return priceListIconURL;
      case NotificationTypeEnum.Template:
        return templatesIconURL;
      case NotificationTypeEnum.ConflictInvoice:
        return invoicesIconURL;
      default:
        return '';
    }
  };

  const onMessageClicked = () => {
    if (type === NotificationTypeEnum.Requisition) {
      const { requisitionId } = data;
      if (markAsRead) {
        markAsRead(id);
      }
      navigate(`/requisitions/view/${requisitionId}`);
    }
    if (type === NotificationTypeEnum.ConflictInvoice) {
      if (markAsRead) {
        markAsRead(id);
      }
      navigate(`/conflict-invoice/view/${data.id}`);
    }
  };

  return (
    <div className="main-notification-wrapper">
      <div className="main-notification-wrapper-profile">
        <div className="main-notification-wrapper-profile-img-wrapper">
          <img
            src={resolveIcon()}
            alt="Profile"
            className={classNames(
              'main-notification-wrapper-profile-img',
              `${type.toString().toLowerCase()}`
            )}
          />
        </div>
      </div>
      <div className="main-notification-wrapper-text">
        <div className="main-notification-wrapper-text-heading">
          <h6 className="main-notification-wrapper-text-heading-h6">
            {name}
            <span
              className="main-notification-wrapper-text-heading-span"
              onClick={onMessageClicked}
            >
              {message.length > 110
                ? message.substring(0, 70) + '...'
                : message}
            </span>
          </h6>
          <p className="main-notification-wrapper-text-heading-time">
            {moment(date).format('DD/MM/YYYY hh:mm A')}
          </p>
        </div>
      </div>
      <span
        className="main-notification-wrapper-read"
        onClick={markAsClickedHandler}
      >
        {read ? '' : 'Mark as read'}
      </span>
    </div>
  );
};

export default NotificationCard;
