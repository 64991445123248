import React from 'react';
import { toaster } from 'baseui/toast';
import { onMessage } from 'firebase/messaging';

import { event } from 'core/events';
// import { INotification } from 'modules/shared/interfaces/notification.interface';
// import { getSocketClient } from 'socket';
import './index.scss';
import { REFETCH_NOTIFICATION_LIST } from 'constants/events';
import { FirebaseService } from 'core/services/firebase';
import { Axios } from 'core/services/http';
import { AppContext } from 'context';

function CustomToastContent({
  title,
  message,
  buttonText,
  clicked,
}: {
  title: string;
  message: string;
  buttonText: string;
  clicked: () => void;
}) {
  return (
    <div className="custom-toast-notification">
      <div className="custom-toast-notification__title">{title}</div>
      <div className="custom-toast-notification__body">{message}</div>
      <div className="custom-toast-notification__action">
        <button type="button" onClick={clicked}>
          {buttonText}
        </button>
      </div>
    </div>
  );
}

interface IProps {
  refetch: () => void;
}

const NotificationListener = ({ refetch }: IProps) => {
  const {
    state: { notificationPopupEnabled },
  } = React.useContext(AppContext);
  const axios = new Axios().getInstance();
  // const [isConnected, setIsConnected] = React.useState(false);
  // const socket = getSocketClient();
  const firebase = new FirebaseService();

  const onMessageHandler = async () => {
    onMessage(firebase.getFirebaseMessaging(), (payload: any) => {
      const {
        data: { link, notificationId },
        notification: { body, title },
      } = payload;
      event.dispatch(REFETCH_NOTIFICATION_LIST);
      showNotification(title, body, notificationId, link);
    });
  };

  const markNotificationsAsRead = async (notificationIds: Array<string>) => {
    try {
      const response = await axios.patch('/notifications/read', {
        notificationIds,
      });
      if (response?.data?.success) {
        refetch();
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          console.log(message);
        });
      }
    }
  };

  React.useEffect(() => {
    onMessageHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPopupEnabled]);

  const showNotification = (
    title: string,
    message: string,
    id: string,
    link: string
  ) => {
    if (notificationPopupEnabled) {
      const toasterKey = toaster.positive(
        <CustomToastContent
          title={title}
          message={message}
          buttonText="View requisition"
          clicked={() => {
            markNotificationsAsRead([id]);
            window.open(link, '_blank');
            toaster.clear(toasterKey);
          }}
        />,
        { autoHideDuration: 4000 }
      );
    }
  };

  // React.useEffect(() => {
  //   if (socket && !isConnected) {
  //     socket.connect();
  //     socket.on('connect', () => {
  //       console.log('Socket connected successfully');
  //       setIsConnected(true);
  //     });
  //     socket.on('notification', (notification: INotification) => {
  //       const {
  //         id,
  //         title,
  //         message,
  //         data: { requisitionId },
  //       } = notification;
  //       event.dispatch(REFETCH_NOTIFICATION_LIST);
  //       showNotification(title, message, id, requisitionId);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [socket]);

  return null;
};

export default NotificationListener;
