import React, { useState, useRef, useEffect, useCallback } from 'react';
import './index.scss';

interface Option {
  id: string;
  label: string;
}

interface SelectWithCheckboxesProps {
  options: Option[];
  store: Option[];
  disabled: boolean;
  name?: string;
  handleChange: (store: Option[]) => void;
}

const SelectWithCheckboxes: React.FC<SelectWithCheckboxesProps> = ({
  options,
  store,
  disabled,
  name = 'Store',
  handleChange,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const handleOptionClick = useCallback(
    (option: Option) => {
      const index = store.findIndex(
        (selectedOption) => selectedOption.id === option.id
      );

      if (index === -1) {
        handleChange([...store, option]);
      } else {
        handleChange([...store.slice(0, index), ...store.slice(index + 1)]);
      }
    },
    [store, handleChange]
  );

  const handleSelectAll = useCallback(() => {
    if (store.length === options.length) {
      handleChange([]);
    } else {
      handleChange(options);
    }
  }, [options, store, handleChange]);

  const selectedOptionsText = () => {
    const numSelected = disabled ? options.length : store.length;
    return `${numSelected} ${name}${numSelected === 1 ? '' : 's'} Selected`;
  };

  return (
    <div
      className={`custom-select ${disabled ? 'disabled' : ''}`}
      ref={selectRef}
      style={{
        maxWidth: '100%',
      }}
    >
      <div
        className="select-trigger"
        onClick={() => {
          !disabled && setShowOptions(!showOptions);
        }}
      >
        <span>{selectedOptionsText()}</span>
        <span>&#x25BC;</span>
      </div>
      {showOptions && (
        <div className="options">
          <div className="option select-all" key="select-all">
            <input
              type="checkbox"
              id="select-all"
              checked={store.length === options.length}
              onChange={handleSelectAll}
            />
            <label htmlFor="select-all">Select All</label>
          </div>
          {options.map((option) => (
            <div className="option" key={option.id}>
              <input
                type="checkbox"
                id={option.id}
                checked={store.some(
                  (selectedOption) => selectedOption.id === option.id
                )}
                onChange={() => handleOptionClick(option)}
              />
              <label htmlFor={option.id}>{option.label}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectWithCheckboxes;
