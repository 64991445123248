import { FirebaseApp, initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';

import config from 'config';

export class FirebaseService {
  private firebaseApp: FirebaseApp;
  private messaging: Messaging;

  constructor() {
    this.initialize();
  }

  private initialize() {
    if (!this.firebaseApp) {
      this.firebaseApp = initializeApp(config.FIREBASE_CONFIG);
    }
  }

  getFirebaseMessaging() {
    if (!this.messaging) {
      this.messaging = getMessaging(this.firebaseApp);
    }
    return this.messaging;
  }

  getFCMToken() {
    return getToken(this.getFirebaseMessaging(), {
      vapidKey: config.FIREBASE_VAPID_KEY,
    });
  }
}
