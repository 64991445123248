import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'styletron-react';
import { Accordion, Panel } from 'baseui/accordion';
import { AccordionGroup } from 'modules/shared/interfaces/master-items.interface';

interface CustomAccordionProps {
  itemList: AccordionGroup[];
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({ itemList }) => {
  const [css] = useStyletron();
  const navigate = useNavigate();
  const titleStyles = css({
    fontSize: '16px',
    marginBottom: '16px',
    fontWeight: '500',
    paddingLeft: '14px',
    width: 'fit-content',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  });

  return (
    <Accordion
      accordion
      overrides={{
        Header: {
          style: ({ $theme }) => ({
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
          }),
        },
      }}
    >
      {itemList.map((itemGroup, index) => (
        <Panel key={index} title={itemGroup.title}>
          {itemGroup.items.map(({ id, title, route }) => (
            <div
              className={titleStyles}
              key={id}
              onClick={() => navigate(route)}
            >
              {title}
            </div>
          ))}
        </Panel>
      ))}
    </Accordion>
  );
};

export default CustomAccordion;
