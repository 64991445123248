import colors from 'constants/colors';
import React from 'react';
import { useStyletron } from 'styletron-react';
import searchIconURL from 'assets/icons/search.svg';

interface IProps {
  placeholder: string;
  buttonName: string;
  inputValue?: string;
  inputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchClicked?: () => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CustomSearch = ({
  placeholder,
  buttonName,
  inputValue,
  inputChange,
  searchClicked,
  handleKeyDown,
}: IProps) => {
  const [css] = useStyletron();
  const searchWrapper = css({
    display: 'flex',
    border: '1px solid rgba(13, 38, 107, 0.1)',
    backgroundColor: colors.white,
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '10px',
    overflowX: 'hidden',
  });
  const iconInputWrapper = css({
    display: 'flex',
    height: '100%',
    width: '0px',
    alignItems: 'center',
    padding: '10px 12px',
  });
  const iconStyle = css({ width: '20px', height: '20px', marginRight: '8px' });
  const inputStyle = css({
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    // minWidth: '260px',
  });
  const buttonWrapper = css({ padding: '4px' });
  const buttonStyle = css({
    padding: '4px 12px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });

  return (
    <div className={searchWrapper}>
      <div className={iconInputWrapper}>
        <img className={iconStyle} src={searchIconURL} alt="Search" />
        <input
          className={inputStyle}
          type="text"
          placeholder={placeholder}
          onChange={inputChange}
          value={inputValue}
          onKeyDown={(e) => handleKeyDown && handleKeyDown(e)}
        />
      </div>
      <div className={buttonWrapper}>
        <input
          className={buttonStyle}
          type="button"
          value={buttonName}
          onClick={searchClicked}
        />
      </div>
    </div>
  );
};

export default CustomSearch;
