import { useRef } from 'react';
import { useStyletron } from 'styletron-react';

interface IProps {
  value: number | undefined;
  disabled?: boolean;
  onValueChange: (event: any) => void;
  onKeyDown?: (event: any) => void;
  inputRef?: any;
  placeholder?: string;
  positive?: boolean;
}

const NumberInput = ({
  inputRef,
  onValueChange,
  disabled,
  value,
  onKeyDown,
  placeholder = '',
  positive = false,
}: IProps) => {
  //if inputRef is not passed, use numberInputRef
  const numberInputRef = useRef<any>(null);

  const [css] = useStyletron();

  let style = css({
    height: '27px',
    borderRadius: '8px',
    width: '80%',
    padding: '2px 8px',
    fontSize: '14px',
    fontFamily: 'poppins-regular',
  });

  if (positive) {
    style = css({
      height: '27px',
      borderRadius: '8px',
      width: '80%',
      padding: '2px 8px',
      fontSize: '14px',
      fontFamily: 'poppins-regular',
      border: '2px solid rgb(102, 209, 158)',
      backgroundColor: 'rgb(230, 242, 237)',
      //:focus
      ':focus': {
        outline: 'none',
        border: '2px solid #111',
        backgroundColor: 'rgb(246, 246, 246)',
      },
    });
  }

  return (
    <input
      disabled={!!disabled}
      type="number"
      ref={inputRef || numberInputRef}
      placeholder={placeholder}
      value={value}
      onChange={onValueChange}
      className={style}
      onKeyDown={onKeyDown}
      onWheel={() => {
        if (inputRef) {
          inputRef.current && inputRef?.current?.blur();
        } else {
          numberInputRef.current && numberInputRef?.current?.blur();
        }
      }}
    />
  );
};

export default NumberInput;
