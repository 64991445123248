import { AUTH_TOKEN, AUTH_USER } from 'constants/auth';
import { IAuthUser } from 'modules/shared/interfaces/auth.interface';

export class AuthService {
  private authToken: string | undefined;
  private authUser: IAuthUser | undefined;
  private companyId: string;

  setAuthToken(token: string) {
    this.authToken = token;
    localStorage.setItem(AUTH_TOKEN, this.authToken);
  }

  getAuthToken(): string {
    if (!this.authToken) {
      this.authToken = localStorage.getItem(AUTH_TOKEN) || '';
    }
    return this.authToken;
  }

  setAuthUser(user: IAuthUser) {
    this.authUser = user;
    localStorage.setItem(AUTH_USER, JSON.stringify(this.authUser));
  }

  getAuthUser(): IAuthUser {
    if (!this.authUser) {
      const authUser = localStorage.getItem(AUTH_USER);
      if (authUser) {
        this.authUser = JSON.parse(authUser);
      }
    }
    return this.authUser as IAuthUser;
  }

  getCompanyId(): string {
    if (!this.companyId) {
      this.companyId = process.env.REACT_APP_COMPANY_ID || '';
    }
    return this.companyId;
  }

  logout(): void {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_USER);
    this.authToken = undefined;
    this.authUser = undefined;
  }
}
