const colors = {
  primaryGreen: '#09B08C',
  primaryBlue: '#063360',
  primaryNavyBlue: '#0041A1',
  background: '#F4F7FA',
  lightBlue: '#00aeef',
  darkBlue: '#445578',
  mediumBlue: '#1D4E89',
  white: '#ffffff',
  black: '#000000',
  red: '#e81313',
  orange: '#ff7112',
  lighterGray: '#f5f5f5',
  lightGray: '#ececec',
  mediumGray: '#9f9f9f',
  darkerGray: '#5e5e5e',
  darkGray: '#505050',
  darkestGray: '#797979',
  backgroundGray: '#e5e5e5',
  lightGreen: '#22d822',
  darkGreen: '#1abc9c',
  lightOrange: '#fdaa81',
  textBlack: '#1E202A',
  disabledColor: '#09b08c69',
};

export default colors;
