import React from 'react';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { useStyletron } from 'styletron-react';
import colors from 'constants/colors';
import { NOTIFICATION_POPUP } from 'constants/notification';
import { AppContext, DispatcherAction } from 'context';

const NotificationToggleMenu = () => {
  const {
    state: { notificationPopupEnabled },
    dispatcher,
  } = React.useContext(AppContext);
  const [css] = useStyletron();
  const main = css({
    position: 'absolute',
    width: '200px',
    background: '#F4F7FA',
    zIndex: 2222,
    height: '20px',
    right: '0',
    top: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 15px',
  });
  const label = css({
    fontSize: '16px',
    color: colors.darkBlue,
  });

  const toggleClicked = (value: boolean) => {
    localStorage.setItem(NOTIFICATION_POPUP, value ? '1' : '0');
    dispatcher(DispatcherAction.NotificationPopup, value);
  };

  return (
    <div className={main}>
      <p className={label}>Notification popup</p>
      <Checkbox
        checked={notificationPopupEnabled}
        onChange={(e) => toggleClicked(e.currentTarget.checked)}
        checkmarkType={STYLE_TYPE.toggle_round}
      ></Checkbox>
    </div>
  );
};

export default NotificationToggleMenu;
