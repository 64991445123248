import config from 'config';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AuthService } from './auth';
import { useNavigate } from 'react-router-dom';
import { event } from 'core/events';
import {
  ON_JWT_EXPIRED,
  ON_JWT_REFRESHED,
  ON_NETWORK_ERROR,
  ON_SERVER_ERROR,
} from 'constants/events';

export class Axios {
  private instance: AxiosInstance | undefined;
  private options: AxiosRequestConfig;
  private authService: AuthService;
  navigate = useNavigate();

  constructor() {
    this.options = {
      baseURL: config.API_URL,
      headers: { 'Content-Type': 'application/json' },
    };
    this.authService = new AuthService();
  }

  private setInstance(): void {
    if (!this.instance) {
      this.instance = axios.create(this.options);
      this.instance.interceptors.request.use((config: any) => {
        const token = this.authService.getAuthToken();
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        config.headers['timezone'] = `${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`;
        config.headers['company'] = this.authService.getCompanyId();
        return config;
      });
      this.instance.interceptors.response.use(
        (response: any) => {
          if (response.headers['refresh-token']) {
            this.authService.setAuthToken(response.headers['refresh-token']);
            event.dispatch(ON_JWT_REFRESHED);
          }
          return response;
        },
        (error) => {
          if (
            error.response.status === 403 &&
            error.response.data?.message === 'jwt_error'
          ) {
            event.dispatch(ON_JWT_EXPIRED);
            return Promise.reject(error);
          }
          if (
            error.response.status === 500 &&
            error.response.data?.message === 'internal_server_error'
          ) {
            event.dispatch(ON_SERVER_ERROR);
            return Promise.reject(error);
          }
          if (error?.code === 'ERR_NETWORK') {
            event.dispatch(ON_NETWORK_ERROR);
            return Promise.reject(error);
          }
          return Promise.reject(error);
        }
      );
    }
  }

  getInstance(): AxiosInstance {
    if (!this.instance) {
      this.setInstance();
    }
    return this.instance as AxiosInstance;
  }
}
