import { KIND } from 'baseui/button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

const InvalidSupplyDateModal = ({
  isOpen,
  onClose,
  invalidSupplyDateItems,
}: any) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          },
        },
        Dialog: {
          style: {
            maxHeight: '85vh',
            overflowY: 'auto',
          },
        },
      }}
    >
      <ModalHeader>Invalid Supply Date</ModalHeader>
      <ModalBody>
        <p>Following items have invalid supply date: </p>
        <div>
          {invalidSupplyDateItems.map((item: any) => (
            <div
              key={item.id}
              style={{
                margin: '5px 0',
              }}
            >
              {item.supplierProductCode} - {item.supplierProductName}
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={KIND.primary} onClick={onClose}>
          Close
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default InvalidSupplyDateModal;
