import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Input, SIZE } from 'baseui/input';
import colors from 'constants/colors';

import classes from './style.module.css';
import { Select } from 'baseui/select';
import { useStyletron } from 'styletron-react';
import { Axios } from 'core/services/http';
import { toaster } from 'baseui/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker } from 'baseui/datepicker';
import { Spinner } from 'baseui/spinner';
import { StyledTab, Tab, Tabs } from 'baseui/tabs';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import requisitionsIconURL from 'assets/icons/requisitions.svg';
import CustomIconButton from 'modules/shared/components/icon-button';
import saveIconUrl from 'assets/icons/save.svg';
import arrowExpandIconUrl from 'assets/icons/arrows-expand.svg';
import arrowCollapseIconUrl from 'assets/icons/arrows-collapse.svg';
import addIconUrl from 'assets/icons/create.svg';
import convertIconUrl from 'assets/icons/convert.svg';
import informationDateCalendar from 'assets/icons/information-date-calendar.svg';
import threeDotIconUrl from 'assets/icons/three-dots-vertical.svg';
import { LabelMedium } from 'baseui/typography';
import { Accordion, Panel } from 'baseui/accordion';
import { PLACEMENT, StatefulTooltip, TRIGGER_TYPE } from 'baseui/tooltip';
import { Block } from 'baseui/block';
import numberWithCommas from 'modules/shared/number-with-commas';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';
import { KIND } from 'baseui/button';
import ReplaceItemsModal from './ReplaceItemsModal';
import SplitItemsModal from './SplitItemsModal';
import { Textarea } from 'baseui/textarea';
import NumberInput from 'modules/shared/components/numberInput';
import SupplyDateModal from './SupplyDateModal';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import moment from 'moment';
import InvalidSupplyDateModal from '../create/InvalidSupplyDateModal';
import CustomSupplyDateModal from '../create/CustomSupplyDateModal';

const SORT_OPTIONS = [
  {
    id: 1,
    label: 'Quantity Added',
    value: 'quantityAdded',
  },
  {
    id: 2,
    label: 'Quantity Remaining',
    value: 'quantityRemaining',
  },
];

const BREADCRUMB_ITEMS = [{ name: 'Process Requisition', route: '' }];

function TabOverride({ children, ...rest }: any) {
  return (
    <StyledTab {...rest}>
      <LabelMedium
        overrides={{
          Block: {
            style: { color: 'inherit', ':hover': { color: 'inherit' } },
          },
        }}
      >
        {children}
      </LabelMedium>
    </StyledTab>
  );
}

const tabStyle = ({ $active, $disabled, $theme }: any) => ({
  color: $active ? colors.darkGreen : colors.black,
  borderBottomWidth: '2px',
  borderBottomStyle: 'solid',
  borderBottomColor: $active ? colors.darkGreen : 'transparent',
  paddingBottom: '8px',
  ':hover': $disabled
    ? {}
    : {
        color: colors.darkGreen,
      },
  ':focus': $disabled
    ? {}
    : {
        color: colors.darkGreen,
      },
});

const dropdownIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="#063360"
  >
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z" />
  </svg>
);

const RequisitionLine = ({
  user,
  onChange,
  data,
  onChangeVendorId,
  onDelete,
  requisition,
  setReplaceItemsModal,
  setReplaceItem,
  setSplitItemsModal,
  setSplitItem,
  setSupplyDateModalOpen,
  setSupplyDateItem,
  supplyDate,
}: any) => {
  const [css] = useStyletron();

  const axios = new Axios().getInstance();
  const [vendorOptions, setVendorOptions] = useState([]);
  const [vendor, setVendor] = useState<any>(null);

  const preFilledLineItem = css({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr 20% 1fr 1fr 0.7fr',
    alignItems: 'center',
    gap: '9px',
    borderBottom: '1px solid #cecece',
    padding: '5px 6px',
    wordWrap: 'break-word',
    minWidth: '900px',
    overflowX: 'auto',
  });
  const iconWrapper = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '34px',
    height: '34px',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    ':hover': {
      backgroundColor: '#ebebeb',
    },
  });
  const crossIconStlyes = css({
    width: '18px',
    cursor: 'pointer',
  });

  const moreIconStlyes = css({
    width: '20px',
    cursor: 'pointer',
  });

  const actionLink = css({
    fontSize: '16px',
    margin: '5px 0',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  });

  const getVendorForProduct = async () => {
    const vendorProductId = data.vendorProductId.id;
    const requisitionId = requisition.id;
    try {
      const response = await axios.get(
        `/requisition/pricelists/${requisitionId}/${vendorProductId}`
      );
      if (response?.data?.success) {
        const priceLists = response.data.data;
        const vendorOptions = priceLists.map((priceList: any) => ({
          id: priceList.vendorId._id,
          label: priceList.vendorId.name,
          priceWoVAT: priceList.priceWoVAT,
        }));
        setVendorOptions(vendorOptions);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  useEffect(() => {
    if (data.vendorProductId?.id && data.vendorProductId?.id !== '') {
      getVendorForProduct();
    }
    if (data.vendorProductId?.id === '') {
      setVendorOptions([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.vendorProductId]);

  // find the vendor from the vendorOptions array
  useEffect(() => {
    const selectedVendor = vendorOptions.find(
      (vendor: any) =>
        vendor?.id === data.vendorId?.id || vendor?.id === data.vendorId?._id
    );
    if (!selectedVendor && vendorOptions.length > 0) {
      const newVendor = {
        value: [vendorOptions[0]],
      };
      onChangeVendorId(newVendor, data._id);
      setVendor(vendorOptions[0]);
      return;
    }
    setVendor(selectedVendor);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorOptions, data.vendorId]);

  return (
    <>
      <div className={preFilledLineItem}>
        <span>{data?.vendorProductId?.supplierProductCode}</span>
        <span>
          <StatefulTooltip
            content={() => (
              <Block padding={'20px'}>
                {data?.vendorProductId?.supplierProductName}
              </Block>
            )}
            showArrow
            returnFocus
            autoFocus
          >
            {data?.vendorProductId?.supplierProductName?.length > 35 ? (
              <span>
                {data?.vendorProductId?.supplierProductName.slice(0, 35)}...
              </span>
            ) : (
              <span>{data?.vendorProductId?.supplierProductName}</span>
            )}
          </StatefulTooltip>
        </span>
        <span>{data?.vendorProductId?.productPackagingUnit?.name || '-'}</span>
        <span>
          {data?.priceWoVAT === undefined || null
            ? '-'
            : numberWithCommas(data?.priceWoVAT)}
        </span>
        <span>
          {numberWithCommas(
            data.quantity * (data?.vendorId?.priceWoVAT || data?.priceWoVAT)
          )}
        </span>
        <span>
          <Select
            positive
            placeholder="Enter Vendor"
            options={vendorOptions}
            value={vendor ? [vendor] : []}
            onChange={(e) => {
              onChangeVendorId(e, data._id);
            }}
            disabled={!user?.manualAddInPR}
            size={SIZE.compact}
            overrides={{
              ControlContainer: {
                style: {
                  width: '100%', // Set the desired width here
                },
              },
            }}
          />
        </span>
        <span>
          <NumberInput
            positive
            value={data?.quantity}
            onValueChange={(e) => {
              onChange(e, 'quantity', data._id);
            }}
            placeholder="Quantity"
          />
        </span>
        <span
          style={{
            textAlign: 'right',
          }}
        >
          {data?.vendorProductId?.productPackagingUnit?.conversionRate
            ? `${
                data?.quantity *
                data?.vendorProductId?.productPackagingUnit?.conversionRate
              } ${
                data?.vendorProductId?.productPackagingUnit?.measureUnitId?.name
              }`
            : '-'}
        </span>
        {/* <span>
          <DatePicker
            positive
            value={
              data.supplyDate
                ? new Date(data.supplyDate)
                : new Date(requisition.SupplyDate)
            }
            onChange={
              //@ts-ignore
              ({ date }: any) => {
                // @ts-ignore
                const dateObj = new Date(date);
                //set the date to 9:00 AM
                dateObj.setHours(9, 0, 0, 0);
                const dataEvent = {
                  target: {
                    value: dateObj.toISOString(),
                  },
                };
                onChange(dataEvent, 'supplyDate', data._id);
              }
            }
            formatString="dd/MM/yyyy"
            size={SIZE.compact}
          />
        </span> */}
        <span
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '2px',
          })}
        >
          <span>
            {data?.supplyDate &&
              !moment(data?.supplyDate).isSame(moment(supplyDate), 'day') && (
                <StatefulTooltip
                  content={() => (
                    <Block padding={'3px'}>
                      Changed Supply Date:{' '}
                      {data?.supplyDate
                        ? moment(data?.supplyDate).format('DD/MM/YYYY')
                        : '-'}
                    </Block>
                  )}
                  showArrow
                  returnFocus
                  autoFocus
                >
                  <span className={iconWrapper}>
                    <img
                      className={moreIconStlyes}
                      src={informationDateCalendar}
                      alt="supplyDateInfo"
                    />
                  </span>
                </StatefulTooltip>
              )}
          </span>
          <span>
            <StatefulTooltip
              content={({ close }: any) => (
                <Block
                  padding={'10px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <span
                    className={actionLink}
                    onClick={() => {
                      setReplaceItemsModal(true);
                      setReplaceItem(data);
                      close();
                    }}
                  >
                    Replace item
                  </span>
                  <span
                    className={actionLink}
                    onClick={() => {
                      setSplitItemsModal(true);
                      setSplitItem(data);
                      close();
                    }}
                  >
                    Split Quantity
                  </span>
                  <span
                    className={actionLink}
                    onClick={() => {
                      setSupplyDateModalOpen(true);
                      setSupplyDateItem(data);
                      close();
                    }}
                  >
                    Change Supply Date
                  </span>
                  <span
                    className={actionLink}
                    onClick={() => {
                      onDelete(data?._id);
                    }}
                  >
                    Remove line
                  </span>
                </Block>
              )}
              triggerType={TRIGGER_TYPE.click}
              returnFocus
              autoFocus
              placement={PLACEMENT.bottomRight}
            >
              <span className={iconWrapper}>
                <img
                  className={moreIconStlyes}
                  src={threeDotIconUrl}
                  alt="moreIcon"
                />
              </span>
            </StatefulTooltip>
          </span>
        </span>
      </div>
    </>
  );
};

interface IRequisitionLine {
  _id: string;
  vendorProductId: {
    label: string;
    id: string;
  };
  quantity: string;
  supplyDate: string;
  vendorId: {
    label: string;
    id: string;
  };
  isChangedAfterSubmit?: boolean;
  blockVendorEmails?: boolean;
}

interface IRequisitionLineNew {
  _id: string;
  vendorProductId: {
    label: string;
    id: string;
    categoryName: string;
  };
  quantity: string;
  supplyDate: string;
  vendorId: {
    label: string;
    id: string;
    priceWoVAT: number;
  };
}

const ProcessRequisition = () => {
  const user = JSON.parse(localStorage.getItem('auth-user') || '{}');

  const { id } = useParams();

  const [templateOptions, setTemplateOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [storesFetched, setStoresFetched] = useState(false);
  const [vendorProductsFetched, setVendorProductsFetched] = useState(false);
  const [vendorProductOptions, setVendorProductOptions] = useState([]);
  const [selectedStore, setSelectedStore] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any[]>([]);
  const [supplyDate, setSupplyDate] = useState<Date | null>(null);
  const [requisition, setRequisition] = useState<any>(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [sortOption, setSortOption] = useState([SORT_OPTIONS[0]]);
  const [requisitionFilter, setRequisitionFilter] = useState('By Supplier');
  const [isNewRequisitionShown, setIsNewRequisitionShown] = useState(false);
  const [quantityIssueModalOpen, setQuantityIssueModalOpen] = useState(false);
  const [quantityIssueItems, setQuantityIssueItems] = useState<any>([]);
  const [replaceItemsModal, setReplaceItemsModal] = useState(false);
  const [splitItemsModal, setSplitItemsModal] = useState(false);
  const [changeSupplyDateModalOpen, setChangeSupplyDateModalOpen] =
    useState(false);
  const [supplyDateItem, setSupplyDateItem] = useState<any>(null);
  const [replaceItem, setReplaceItem] = useState<any>(null);
  const [splitItem, setSplitItem] = useState<any>(null);
  const [expandAll, setExpandAll] = useState(true);

  const [requisitionLines, setRequisitionLines] = useState<IRequisitionLine[]>(
    []
  );
  const [invalidSupplyDateItems, setInvalidSupplyDateItems] = useState<any[]>(
    []
  );
  const [invalidSupplyDateModalOpen, setInvalidSupplyDateModalOpen] =
    useState(false);
  const [customSupplyDateModalOpen, setCustomSupplyDateModalOpen] =
    useState(false);
  const [customSupplyDateItems, setCustomSupplyDateItems] = useState<any[]>([]);

  const [newRequisitionLine, setNewRequisitionLine] =
    useState<IRequisitionLineNew>({
      _id: uuidv4(),
      vendorProductId: {
        label: '',
        id: '',
        categoryName: '',
      },
      quantity: '',
      supplyDate: '',
      vendorId: {
        label: '',
        id: '',
        priceWoVAT: 0,
      },
    });

  const [newRequisitionVendorOptions, setNewRequisitionVendorOptions] =
    useState<any[]>([]);

  const [requisitionLinesByCategory, setRequisitionLinesByCategory] = useState<
    any[]
  >([]);

  const [requisitionLinesByVendor, setRequisitionLinesByVendor] = useState<
    any[]
  >([]);

  const [initialStepDone, setInitialStepDone] = useState(false);

  const [css] = useStyletron();

  const axios = new Axios().getInstance();
  const navigate = useNavigate();

  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    minWidth: '140px',
    ':disabled': {
      backgroundColor: colors.mediumGray,
      cursor: 'not-allowed',
    },
  });
  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const buttonLineWrapper = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
  });

  const expandButtonsWrapper = css({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  });

  const customButtonsWrapper = css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    flex: 1,
    width: '100%',
    '@media (max-width: 900px)': {
      justifyContent: 'flex-start',
    },
  });
  const customButtonWrapper = css({
    marginRight: '8px',
    marginBottom: '8px',
  });
  const wrapperSupplyDate = css({
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
  });
  const datePickerWrapper = css({
    width: '150px',
  });
  const wrapper = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  });
  const reportsListWrapper = css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '0 16px',
    // marginTop: '32px',
    '@media (max-width: 600px)': {
      padding: '0 6px',
    },
  });
  const subCategoryWrapper = css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    overflow: 'auto',
    minWidth: '900px',
  });
  const labelsWrapper = css({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr 20% 1fr 1fr 0.7fr',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 6px',

    backgroundColor: '#063360',
    color: 'white',
    fontWeight: 700,
    fontSize: '16px',
  });
  const topLabelsWrapper = css({
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    gap: '20px',
  });
  const topLabelWrapper = css({
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  });
  const topLabel = css({
    fontSize: '17px',
    fontWeight: '500',
  });
  const topLabelValue = css({
    fontSize: '17px',
    fontFamily: 'poppins-regular',
  });
  const newRequistionWrapper = css({
    display: 'grid',
    gridTemplateColumns: '25% 20% 10% 15% 10% 12% 3%',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 8px',
    minWidth: '900px',
    overflowX: 'auto',
  });
  const newRequisitionHeader = css({
    display: 'grid',
    gridTemplateColumns: '25% 20% 10% 15% 10% 12% 3%',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #cecece',
    padding: '4px 6px',

    backgroundColor: '#063360',
    color: 'white',
    fontWeight: 700,
    fontSize: '16px',
  });
  const addWrapper = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  });
  const addStlyes = css({
    width: '70%',
    cursor: 'pointer',
  });
  const textAreaWrapper = css({
    marginTop: '16px',
    padding: '2px 12px',
  });
  const getStores = async () => {
    try {
      setStoresFetched(false);
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        const fetchedStores = response.data.data;
        const storeOptions = fetchedStores.map((store: any) => ({
          label: store.name,
          id: store.id,
        }));
        setStoreOptions(storeOptions);
        setStoresFetched(true);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      setStoresFetched(true);
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getTemplates = async () => {
    try {
      const response = await axios.get('/requisition-template');
      if (response?.data?.success) {
        const fetchedTemplates = response.data.data;
        const liveTemplates = fetchedTemplates.filter(
          (template: any) => template.status === 'Live'
        );
        // now filter the templates based on the selected store, if the templateStoreList contains the selected store then only show that template, and if the validForAllStores is true then show that template
        const filteredTemplates = liveTemplates.filter(
          (template: any) =>
            template.validForAllStores ||
            template.includedStoreList.some(
              // @ts-ignore
              (storeId: any) => storeId === selectedStore[0].id
            )
        );
        const templateOptions = filteredTemplates.map((template: any) => ({
          label: template.templateName,
          id: template.id,
        }));
        const BlankTemplate = {
          label: 'Blank Template',
          id: 'blank',
        };
        if (user?.createFromBlankTemplate === true) {
          templateOptions.unshift(BlankTemplate);
        }
        setTemplateOptions(templateOptions);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getVendorProducts = async () => {
    setVendorProductsFetched(false);
    try {
      const response = await axios.get('/supplierProducts', {
        params: {
          all: true,
        },
      });
      if (response?.data?.success) {
        const fetchedVendorProducts = response.data.data?.products;
        const vendorProductOptions = fetchedVendorProducts.map(
          (vendorProduct: any) => ({
            label: `${vendorProduct?.supplierProductCode}-${vendorProduct?.supplierProductName}-${vendorProduct?.packagingUnit?.name}`,
            id: vendorProduct.id,
            categoryName: vendorProduct.prCategories[0]?.erpName,
            ...vendorProduct,
          })
        );
        setVendorProductOptions(vendorProductOptions);

        setVendorProductsFetched(true);
      }
    } catch (error: any) {
      setVendorProductsFetched(true);
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const onChangeTemplate = (params: any) => {
    setSelectedTemplate(params.value);
  };

  const onChangeStore = (params: any) => {
    setSelectedStore(params.value);
  };

  const getTemplate = async (templateId: string) => {
    try {
      const response = await axios.get(`/requisition-template/${templateId}`);
      if (response?.data?.success) {
        const fetchedTemplate = response.data.data;
        return fetchedTemplate;
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const CreateRequisitionHandler = async () => {
    setInitialStepDone(true);
    // @ts-ignore
    const templateId = selectedTemplate[0].id;
    if (templateId !== 'blank') {
      const template = await getTemplate(templateId);
      const requisitionLines = template.templateLines.map(
        (templateLine: any) => ({
          _id: uuidv4(),
          vendorProductId: {
            label: templateLine.vendorProductId.supplierProductName,
            id: templateLine.vendorProductId._id,
          },
          quantity: '',
        })
      );
      setRequisitionLines(requisitionLines);
    } else {
      const requisitionLines = [
        {
          _id: uuidv4(),
          vendorProductId: {
            label: '',
            id: '',
          },
          quantity: '',
          supplyDate: '',
          vendorId: {
            label: '',
            id: '',
          },
        },
      ];
      setRequisitionLines(requisitionLines);
    }
  };

  const requisitionLineChangeHandler = (e: any, field: string, id: string) => {
    const updatedRequisitionLines = requisitionLines.map((line: any) => {
      if (line._id === id) {
        return {
          ...line,
          [field]: e.target.value,
          isChangedAfterSubmit: true,
        };
      }
      return line;
    });
    // @ts-ignore
    setRequisitionLines(updatedRequisitionLines);
  };

  const requisitionLinesSupplyDateChangeHandler = (date: any, id: string) => {
    const updatedRequisitionLines = requisitionLines.map((line: any) => {
      if (line._id === id) {
        return {
          ...line,
          supplyDate: date.toISOString(),
          isChangedAfterSubmit: true,
        };
      }
      return line;
    });
    // @ts-ignore
    setRequisitionLines(updatedRequisitionLines);
  };

  const vendorProductIdChangeHandler = (params: any, id: string) => {
    const updatedRequisitionLines = requisitionLines.map((requisitionLine) => {
      if (requisitionLine._id === id) {
        return { ...requisitionLine, vendorProductId: params.value[0] };
      }
      return requisitionLine;
    });
    setRequisitionLines(updatedRequisitionLines);
  };

  const vendorIdChangeHandler = (params: any, id: string) => {
    const updatedRequisitionLines = requisitionLines.map((requisitionLine) => {
      if (requisitionLine._id === id) {
        return {
          ...requisitionLine,
          vendorId: params.value[0],
          priceWoVAT: params.value[0].priceWoVAT,
          isChangedAfterSubmit: true,
        };
      }
      return requisitionLine;
    });
    setRequisitionLines(updatedRequisitionLines);
  };

  const addNewRequisitionLine = () => {
    //check if the newRequisitionLine is valid
    if (
      newRequisitionLine.vendorProductId.id === '' ||
      newRequisitionLine.quantity === '' ||
      newRequisitionLine.vendorId.id === ''
    ) {
      toaster.negative('Please fill all the fields', {
        autoHideDuration: 4000,
      });
      return;
    }
    //we need to add newRequsitionLine to the requisitionLines array
    const updatedRequisitionLines = [
      ...requisitionLines,
      {
        ...newRequisitionLine,
        priceWoVAT: newRequisitionLine.vendorId.priceWoVAT,
        categoryName: newRequisitionLine.vendorProductId.categoryName,
        isChangedAfterSubmit: true,
      },
    ];
    setRequisitionLines(updatedRequisitionLines);
    setNewRequisitionLine({
      _id: uuidv4(),
      vendorProductId: {
        label: '',
        id: '',
        categoryName: '',
      },
      quantity: '',
      supplyDate: '',
      vendorId: {
        label: '',
        id: '',
        priceWoVAT: 0,
      },
    });
    setNewRequisitionVendorOptions([]);
  };

  const deleteRequisitionLineHandler = (id: string) => {
    const updatedTemplateLines = requisitionLines.filter(
      // @ts-ignore
      (templateLine) => templateLine._id !== id
    );
    setRequisitionLines(updatedTemplateLines);
  };

  const checkMinimumOrderValue = () => {
    const vendorsWithMinimumOrderValueNotMet: any[] = [];
    requisitionLinesByVendor.forEach((requisitionLine: any) => {
      if (requisitionLine.totalAmount < requisitionLine.minOrderValue) {
        vendorsWithMinimumOrderValueNotMet.push(requisitionLine.vendorName);
      }
    });

    if (vendorsWithMinimumOrderValueNotMet.length > 0) {
      toaster.negative(
        `Minimum order value not met for ${vendorsWithMinimumOrderValueNotMet.join(
          ', '
        )}`,
        {
          autoHideDuration: 3000,
        }
      );
      return false;
    }
    return true;
  };

  const saveRequisitionHandler = async (shouldNavigate = true) => {
    const updatedRequisitionLines = requisitionLines.map((requisitionLine) => ({
      // @ts-ignore
      vendorProductId: requisitionLine.vendorProductId.id,
      quantity: requisitionLine.quantity,
      vendorId: requisitionLine.vendorId.id || requisitionLine.vendorId,
      priceWoVAT:
        // @ts-ignore
        requisitionLine.vendorId.priceWoVAT || requisitionLine.priceWoVAT,
      supplyDate: requisitionLine.supplyDate || requisition.SupplyDate,
      isChangedAfterSubmit: requisitionLine?.isChangedAfterSubmit,
      blockVendorEmails: requisitionLine?.blockVendorEmails || false,
    }));
    const data = {
      // @ts-ignore
      storeId: selectedStore[0]?.id,
      requisitionLines: updatedRequisitionLines,
      // @ts-ignore
      templateUsed: selectedTemplate[0]?.id,
      SupplyDate: supplyDate,
      companyId: requisition?.companyId?._id,
      requisitionId: requisition?.id,
      process: true,
    };
    try {
      const response = await axios.patch(
        '/requisition/update-with-given-vendor',
        data
      );
      if (response?.data?.success) {
        if (shouldNavigate) {
          toaster.positive('Requisition updated successfully', {
            autoHideDuration: 4000,
          });
          navigate('/requisitions');
        }
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          toaster.negative(message, {
            autoHideDuration: 4000,
          });
        });
      }
    }
  };

  //if the supply date changes or the requisition lines change then we need to save the requisition
  useEffect(() => {
    if (initialStepDone) saveRequisitionHandler(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyDate, requisitionLines]);

  const getRequisition = async () => {
    try {
      const response = await axios.get(`/requisition/${id}`);
      if (response?.data?.success) {
        const fetchedRequisition = response.data.data;
        setRequisition(fetchedRequisition);
        setTotalAmount(fetchedRequisition?.totalAmount || 0);
        setSelectedStore([
          // @ts-ignore
          {
            label: fetchedRequisition.storeId.name,
            id: fetchedRequisition.storeId._id,
          },
        ]);
        // @ts-ignore
        setSelectedTemplate([
          // @ts-ignore
          {
            label: fetchedRequisition?.templateUsed?.name,
            id: fetchedRequisition?.templateUsed?._id,
          },
        ]);
        setInitialStepDone(true);
        const formattedSupplyDate = fetchedRequisition.SupplyDate
          ? new Date(fetchedRequisition.SupplyDate)
          : new Date();
        setSupplyDate(formattedSupplyDate);
        const fetchedRequisitionLines = fetchedRequisition.requisitionLines.map(
          (requisitionLine: any) => {
            const vendorProductOption = vendorProductOptions.find(
              (vendorProductOption) =>
                // @ts-ignore
                vendorProductOption.id === requisitionLine.vendorProductId._id
            );
            return {
              ...requisitionLine,
              vendorProductId: {
                // @ts-ignore
                label: vendorProductOption?.label,
                // @ts-ignore
                id: vendorProductOption?.id,
                // @ts-ignore
                categoryName: vendorProductOption?.categoryName,
                ...requisitionLine.vendorProductId,
              },
              categoryName:
                requisitionLine.vendorProductId.masterProductId.categoryObjectId
                  .erpName,
            };
          }
        );
        if (fetchedRequisitionLines && fetchedRequisitionLines.length > 0) {
          // sort the requisition lines by the quantity
          fetchedRequisitionLines.sort((a: any, b: any) => {
            if (a.quantity > b.quantity) {
              return -1;
            }
            if (a.quantity < b.quantity) {
              return 1;
            }
            return 0;
          });
        }
        setRequisitionLines(
          fetchedRequisitionLines ? fetchedRequisitionLines : []
        );
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        console.log(errors);
      }
    }
  };

  const groupByCategory = () => {
    const groupedRequisitionLines = requisitionLines.reduce(
      (acc: any, line: any) => {
        const categoryName = line.categoryName;
        if (!acc[categoryName]) {
          acc[categoryName] = [];
        }
        acc[categoryName].push(line);
        return acc;
      },
      {}
    );

    const groupedRequisitionLinesArray = Object.keys(
      groupedRequisitionLines
    ).map((key) => ({
      categoryName: key,
      requisitionLines: groupedRequisitionLines[key],
    }));

    // we need to find the total amount for each category
    groupedRequisitionLinesArray.forEach((groupedRequisitionLine: any) => {
      let totalAmount = 0;
      groupedRequisitionLine.requisitionLines.forEach(
        (requisitionLine: any) => {
          const priceWoVAT =
            // @ts-ignore
            requisitionLine?.priceWoVAT ||
            requisitionLine?.vendorId?.priceWoVAT;
          // @ts-ignore
          if (priceWoVAT && requisitionLine?.quantity) {
            // @ts-ignore
            totalAmount += priceWoVAT * requisitionLine?.quantity;
          }
        }
      );
      groupedRequisitionLine.totalAmount = totalAmount;
    });

    setRequisitionLinesByCategory(groupedRequisitionLinesArray);
  };

  const groupByVendor = () => {
    const groupedRequisitionLines = requisitionLines.reduce(
      (acc: any, line: any) => {
        const vendorName = line?.vendorId?.name || line?.vendorId?.label;
        if (!acc[vendorName]) {
          acc[vendorName] = [];
        }
        acc[vendorName].push(line);
        return acc;
      },
      // @ts-ignore
      {}
    );

    const groupedRequisitionLinesArray = Object.keys(
      groupedRequisitionLines
    ).map((key) => ({
      vendorName: key,
      requisitionLines: groupedRequisitionLines[key],
      vendorId: groupedRequisitionLines[key][0]?.vendorId,
    }));

    // we need to find the total amount for each vendor
    groupedRequisitionLinesArray.forEach((groupedRequisitionLine: any) => {
      let totalAmount = 0;
      let blockVendorEmails = false;
      const minOrderValue =
        groupedRequisitionLine.requisitionLines[0].vendorId?.minOrderValue;
      groupedRequisitionLine.requisitionLines.forEach(
        (requisitionLine: any) => {
          const priceWoVAT =
            // @ts-ignore
            requisitionLine?.priceWoVAT ||
            requisitionLine?.vendorId?.priceWoVAT;
          // @ts-ignore
          if (priceWoVAT && requisitionLine?.quantity) {
            // @ts-ignore
            totalAmount += priceWoVAT * requisitionLine?.quantity;
          }
          if (requisitionLine?.blockVendorEmails) {
            blockVendorEmails = true;
          }
        }
      );
      groupedRequisitionLine.totalAmount = totalAmount;
      groupedRequisitionLine.minOrderValue = minOrderValue;
      groupedRequisitionLine.blockVendorEmails = blockVendorEmails;
    });

    setRequisitionLinesByVendor(groupedRequisitionLinesArray);
  };

  useEffect(() => {
    if (requisitionLines.length > 0) {
      groupByCategory();
      groupByVendor();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisitionLines]);

  useEffect(() => {
    if (id && storesFetched && vendorProductsFetched) {
      getRequisition();
      setIsLoading(false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, storesFetched, vendorProductsFetched]);

  useEffect(() => {
    getStores();
    getVendorProducts();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStore) {
      getTemplates();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore]);

  const convertToPOHandler = async () => {
    try {
      const response = await axios.patch(`/requisition/convert-to-order/${id}`);
      if (response?.data?.success) {
        toaster.positive('Requisition converted to PO successfully', {
          autoHideDuration: 3000,
        });
        return response.data.data;
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      toaster.negative(errors[0], {
        autoHideDuration: 3000,
      });
      if (errors?.length) {
        console.error(errors);
      }
    }
  };

  const releaseOrderHandler = async (orderIds: any) => {
    try {
      const response = await axios.patch(`/orders/release`, {
        orderIds,
      });
      if (response?.data?.success) {
        toaster.positive(response.data.message, {
          autoHideDuration: 3000,
        });
        navigate('/requisitions');
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      toaster.negative('Something went wrong in releasing the orders', {
        autoHideDuration: 3000,
      });
      if (errors?.length) {
        console.error(errors);
      }
    }
  };

  const checkHeaderSupplyDate = () => {
    if (supplyDate && moment(supplyDate).isBefore(moment(), 'day')) {
      toaster.negative('Supply date is not valid!', {
        autoHideDuration: 3000,
      });
      return false;
    }
    return true;
  };

  const checkRequisitionLinesSupplyDate = () => {
    const invalidLines: any[] = [];
    requisitionLines.forEach((requisitionLine: any) => {
      if (
        requisitionLine.supplyDate &&
        moment(requisitionLine.supplyDate).isBefore(moment(), 'day')
      ) {
        invalidLines.push({
          supplierProductCode:
            requisitionLine?.vendorProductId?.supplierProductCode,
          supplierProductName:
            requisitionLine?.vendorProductId?.supplierProductName,
          id: requisitionLine._id,
        });
      }
    });
    if (invalidLines.length > 0) {
      setInvalidSupplyDateItems(invalidLines);
      setInvalidSupplyDateModalOpen(true);
      return false;
    }
    return true;
  };

  const checkForCustomSupplyDateOfLines = () => {
    const customLines: any[] = [];
    requisitionLines.forEach((line: any) => {
      if (!moment(line.supplyDate).isSame(moment(supplyDate), 'day')) {
        customLines.push({
          supplierProductCode: line?.vendorProductId?.supplierProductCode,
          supplierProductName: line?.vendorProductId?.supplierProductName,
          id: line._id,
          supplyDate: moment(line.supplyDate).format('DD-MM-YYYY'),
        });
      }
    });
    if (customLines.length > 0) {
      setCustomSupplyDateItems(customLines);
      setCustomSupplyDateModalOpen(true);
      return false;
    }
    return true;
  };

  const ProcessRequisitionHandler = async () => {
    setIsProcessing(true);
    // first save the requisition
    await saveRequisitionHandler(false);
    //check if the minimum order value is met or not
    const isMinimumOrderValueMet = checkMinimumOrderValue();
    if (!isMinimumOrderValueMet) {
      setIsProcessing(false);
      return;
    }
    // then convert it to PO
    const orderIds = await convertToPOHandler();
    if (!orderIds) {
      setIsProcessing(false);
      return;
    }
    // then release the orders
    await releaseOrderHandler(orderIds);
    setIsProcessing(false);
  };

  const checkQuantityIssues = () => {
    const quantityIssues: any[] = [];
    requisitionLines.forEach((requisitionLine) => {
      //if quantity is null or is 0 then add it to the quantityIssues array
      if (!requisitionLine.quantity || Number(requisitionLine.quantity) === 0) {
        quantityIssues.push(requisitionLine);
      }
    });
    if (quantityIssues.length > 0) {
      setQuantityIssueModalOpen(true);
      setQuantityIssueItems(quantityIssues);
      return false;
    }
    setQuantityIssueItems([]);
    if (!checkHeaderSupplyDate()) return;
    if (!checkRequisitionLinesSupplyDate()) return;
    if (!checkForCustomSupplyDateOfLines()) return;
    ProcessRequisitionHandler();
  };

  // we need to update the total amount when the requisition lines change
  useEffect(() => {
    let totalAmount = 0;
    requisitionLines.forEach((requisitionLine) => {
      const priceWoVAT =
        // @ts-ignore
        requisitionLine?.priceWoVAT || requisitionLine?.vendorId?.priceWoVAT;
      // @ts-ignore
      if (priceWoVAT && requisitionLine?.quantity) {
        // @ts-ignore
        totalAmount += priceWoVAT * requisitionLine?.quantity;
      }
    });
    setTotalAmount(totalAmount);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisitionLines]);

  const getVendorForProduct = async () => {
    const vendorProductId = newRequisitionLine.vendorProductId.id;
    const requisitionId = requisition.id;
    try {
      const response = await axios.get(
        `/requisition/pricelists/${requisitionId}/${vendorProductId}`
      );
      if (response?.data?.success) {
        const priceLists = response.data.data;
        const vendorOptions = priceLists.map((priceList: any) => ({
          id: priceList.vendorId._id,
          label: priceList.vendorId.name,
          priceWoVAT: priceList.priceWoVAT,
          minOrderValue: priceList.vendorId?.minOrderValue,
        }));
        setNewRequisitionVendorOptions(vendorOptions);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  useEffect(() => {
    if (newRequisitionLine.vendorProductId.label !== '') {
      getVendorForProduct();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRequisitionLine.vendorProductId.label]);

  //when the newRequisitionLine.vendorPRoductId changes we need to set the vendorId to empty
  useEffect(() => {
    setNewRequisitionLine({
      ...newRequisitionLine,
      vendorId: {
        label: '',
        id: '',
        priceWoVAT: 0,
      },
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRequisitionLine.vendorProductId.id]);

  const replaceRequsitionLineHandler = (line: any) => {
    const updatedRequisitionLines = requisitionLines.map((requisitionLine) => {
      if (requisitionLine._id === line._id) {
        return line;
      }
      return requisitionLine;
    });
    setRequisitionLines(updatedRequisitionLines);
    setReplaceItemsModal(false);
  };

  const splitRequsitionLineHandler = (newLines: any) => {
    //we get newLines which will included splitItem as well so from newLInes we need to remove the splitItem
    const updatedRequisitionLines = requisitionLines.filter(
      (requisitionLine) => requisitionLine._id !== splitItem._id
    );
    //now we need to add the newLines to the updatedRequisitionLines
    const finalRequisitionLines = [...updatedRequisitionLines, ...newLines];
    setRequisitionLines(finalRequisitionLines);
    setSplitItemsModal(false);
  };

  const onBlockVendorEmailsHandler = (value: boolean, vendorId: string) => {
    const updatedRequisitionLines = requisitionLines.map(
      (requisitionLine: any) => {
        if (
          requisitionLine.vendorId._id === vendorId ||
          requisitionLine.vendorId.id === vendorId
        ) {
          return {
            ...requisitionLine,
            blockVendorEmails: value,
          };
        }
        return requisitionLine;
      }
    );
    setRequisitionLines(updatedRequisitionLines);
  };

  const headerSupplyDateChangeHandler = ({ date }: any) => {
    const dateObj = new Date(date);
    //set the date to 9:00 AM
    dateObj.setHours(9, 0, 0, 0);
    setSupplyDate(dateObj);
    //we need to update the supply date for all the requisition lines
    const updatedRequisitionLines = requisitionLines.map((requisitionLine) => ({
      ...requisitionLine,
      supplyDate: dateObj.toISOString(),
    }));

    setRequisitionLines(updatedRequisitionLines);
  };

  return (
    <>
      {!isLoading && !isProcessing && (
        <div className={classes.createRequisition}>
          <div className={classes.heading}>
            <div className={breadcrumbWrapper}>
              <img
                className={iconStyles}
                src={requisitionsIconURL}
                alt="Requisition"
              />
              <Breadcrumb items={BREADCRUMB_ITEMS} />
            </div>
          </div>
          <div className={wrapper}>
            <div
              style={
                {
                  overflowX: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '@media (maxWidth: 568px)': {
                    overflowX: 'scroll',
                  },
                } as React.CSSProperties
              }
            >
              <Tabs
                activeKey={requisitionFilter}
                onChange={(e: any) => setRequisitionFilter(e.activeKey)}
                overrides={{
                  Tab: { component: TabOverride, style: tabStyle },
                  TabBar: { style: { backgroundColor: 'white' } },
                }}
              >
                <Tab title="By Supplier" key="By Supplier" />
                <Tab title="By Category" key="By Category" />
              </Tabs>
            </div>
            {!initialStepDone && <h3>Please choose a template</h3>}
            <div className={topLabelsWrapper}>
              <div className={topLabelWrapper}>
                <p className={topLabel}>Store:</p>
                <span className={topLabelValue}>{selectedStore[0]?.label}</span>
              </div>
              <div className={topLabelWrapper}>
                <p className={topLabel}>Requisition Code:</p>
                <span className={topLabelValue}>
                  {requisition?.requisitionCode}
                </span>
              </div>
              {initialStepDone && (
                <div className={wrapperSupplyDate}>
                  <p className={topLabel}>Supply Date:</p>
                  <div className={datePickerWrapper}>
                    <DatePicker
                      positive
                      //minDate is today
                      minDate={new Date()}
                      value={supplyDate}
                      // @ts-ignore
                      onChange={headerSupplyDateChangeHandler}
                      formatString="dd/MM/yyyy"
                      size={SIZE.compact}
                    />
                  </div>
                </div>
              )}
            </div>
            {!initialStepDone && (
              <div className={classes.createBtn}>
                <button
                  className={buttonStyle}
                  onClick={CreateRequisitionHandler}
                  disabled={
                    selectedStore.length === 0 || selectedTemplate.length === 0
                  }
                >
                  Create
                </button>
              </div>
            )}
          </div>
          {initialStepDone && (
            <div>
              <div className={classes.templateLines}>
                <div className={buttonLineWrapper}>
                  <div className={expandButtonsWrapper}>
                    <div className={customButtonWrapper}>
                      <CustomIconButton
                        icon={arrowExpandIconUrl}
                        name="Expand All"
                        onClick={() => {
                          setExpandAll(true);
                        }}
                      />
                    </div>
                    <div className={customButtonWrapper}>
                      <CustomIconButton
                        icon={arrowCollapseIconUrl}
                        name="Collapse All"
                        onClick={() => {
                          setExpandAll(false);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={customButtonsWrapper}
                    style={{
                      margin: '10px 0',
                    }}
                  >
                    <div className={customButtonWrapper}>
                      <CustomIconButton
                        icon={saveIconUrl}
                        name="Save"
                        onClick={saveRequisitionHandler}
                      />
                    </div>
                    {requisition?.status === 'Submitted' && (
                      <div className={customButtonWrapper}>
                        <CustomIconButton
                          icon={convertIconUrl}
                          name="Convert to PO"
                          onClick={checkQuantityIssues}
                        />
                      </div>
                    )}
                    <div className={customButtonWrapper}>
                      <CustomIconButton
                        icon={addIconUrl}
                        name="Add line Item"
                        onClick={() => {
                          setIsNewRequisitionShown(!isNewRequisitionShown);
                        }}
                      />
                    </div>
                    <div className={classes.total}>
                      <h3>Total: </h3>
                      <h3>{numberWithCommas(totalAmount)}</h3>
                    </div>
                  </div>
                </div>
                <div className={reportsListWrapper}>
                  {/* // NEW Requistion line */}
                  {isNewRequisitionShown && (
                    <div style={{ marginBottom: '10px' }}>
                      <div className={`${newRequisitionHeader}`}>
                        <span>Product</span>
                        <span>Supplier</span>
                        <span>Quantity</span>
                        <span>Price w/o VAT</span>
                        <span>Amount</span>
                        <span>Supply Date</span>
                        <span>
                          <StatefulTooltip
                            content={() => <Block padding={'3px'}>Hide</Block>}
                            showArrow
                            returnFocus
                            autoFocus
                          >
                            <span
                              className={addWrapper}
                              onClick={() => {
                                setIsNewRequisitionShown(false);
                              }}
                            >
                              <DeleteIcon
                                className={classes.deleteIconStyles}
                              />
                            </span>
                          </StatefulTooltip>
                        </span>
                      </div>
                      <div
                        className={newRequistionWrapper}
                        style={{ paddingBottom: '10px' }}
                      >
                        <span>
                          <Select
                            positive
                            size={SIZE.compact}
                            placeholder="Enter Vendor Product Id"
                            options={vendorProductOptions}
                            //give value if vendorProductId is not empty
                            value={
                              newRequisitionLine.vendorProductId.label !== ''
                                ? [newRequisitionLine.vendorProductId]
                                : []
                            }
                            onChange={(e) => {
                              const value = e.value[0];
                              setNewRequisitionLine({
                                ...newRequisitionLine,
                                vendorProductId: {
                                  ...value,
                                  // @ts-ignore
                                  label: value.label,
                                  // @ts-ignore
                                  id: value.id,
                                  categoryName: value.categoryName,
                                  productPackagingUnit: value.packagingUnit,
                                  packagingUnit: value.packagingUnit.id,
                                },
                              });
                            }}
                            disabled={!user?.manualAddInPR}
                          />
                        </span>
                        <span>
                          <Select
                            positive
                            size={SIZE.compact}
                            placeholder="Enter Vendor"
                            options={newRequisitionVendorOptions}
                            //give value if vendorProductId is not empty
                            value={
                              newRequisitionLine.vendorId.label !== ''
                                ? [newRequisitionLine.vendorId]
                                : []
                            }
                            onChange={(e) => {
                              const value = e.value[0];
                              setNewRequisitionLine({
                                ...newRequisitionLine,
                                vendorId: {
                                  // @ts-ignore
                                  label: value.label,
                                  // @ts-ignore
                                  id: value.id,
                                  // @ts-ignore
                                  priceWoVAT: value.priceWoVAT,
                                  // @ts-ignore
                                  minOrderValue: value.minOrderValue,
                                },
                              });
                            }}
                            disabled={!user?.manualAddInPR}
                          />
                        </span>
                        <span>
                          <NumberInput
                            positive
                            value={Number(newRequisitionLine.quantity)}
                            onValueChange={(e) => {
                              setNewRequisitionLine({
                                ...newRequisitionLine,
                                quantity: e.target.value,
                              });
                            }}
                            placeholder="Quantity"
                          />
                        </span>
                        <span>
                          <Input
                            positive
                            size={SIZE.compact}
                            type="number"
                            placeholder="PriceWoVAT"
                            readOnly
                            value={newRequisitionLine.vendorId.priceWoVAT}
                          />
                        </span>
                        <span>
                          <Input
                            positive
                            size={SIZE.compact}
                            type="number"
                            placeholder="Total Amount"
                            value={
                              //@ts-ignore
                              newRequisitionLine.quantity *
                              //@ts-ignore
                              newRequisitionLine.vendorId.priceWoVAT
                            }
                            readOnly
                          />
                        </span>
                        <span>
                          <DatePicker
                            positive
                            size={SIZE.compact}
                            minDate={new Date()}
                            value={
                              newRequisitionLine.supplyDate
                                ? new Date(newRequisitionLine.supplyDate)
                                : supplyDate
                            }
                            onChange={
                              //@ts-ignore
                              ({ date }: any) => {
                                // @ts-ignore
                                const dateString = date.toISOString();
                                setNewRequisitionLine({
                                  ...newRequisitionLine,
                                  //convert date to string
                                  supplyDate: dateString,
                                });
                              }
                            }
                            formatString="dd/MM/yyyy"
                          />
                        </span>
                        <span>
                          <StatefulTooltip
                            content={() => <Block padding={'3px'}>Add</Block>}
                            showArrow
                            returnFocus
                            autoFocus
                          >
                            <span
                              className={addWrapper}
                              onClick={addNewRequisitionLine}
                            >
                              <img
                                className={addStlyes}
                                src={addIconUrl}
                                alt="addIcon"
                              />
                            </span>
                          </StatefulTooltip>
                        </span>
                      </div>
                    </div>
                  )}
                  <Accordion
                    accordion
                    overrides={{
                      Header: {
                        style: ({ $theme }) => ({
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'flex-end',
                          marginBottom: '10px',
                          boxShadow:
                            'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;',
                        }),
                      },
                      ToggleIcon: {
                        component: () => dropdownIcon,
                        style: ({ $theme }) => ({
                          color: colors.primaryGreen,
                        }),
                      },
                      Content: {
                        style: () => ({
                          paddingTop: '0px !important',
                          paddingBottom: '20px !important',
                        }),
                      },
                    }}
                  >
                    {requisitionFilter === 'By Category' &&
                    requisitionLinesByCategory?.length === 0 ? (
                      <h3>No Data Found</h3>
                    ) : (
                      requisitionFilter === 'By Category' &&
                      requisitionLinesByCategory.map((line, key) => {
                        return (
                          <Panel
                            key={key}
                            title={
                              <PanelTitle
                                title={line.categoryName}
                                totalItems={line.requisitionLines.length}
                                showMinOrderValue={false}
                                totalNetAmount={numberWithCommas(
                                  line.totalAmount
                                )}
                              />
                            }
                            expanded={expandAll}
                          >
                            <div className={subCategoryWrapper}>
                              <div className={labelsWrapper}>
                                <span>Product Code</span>
                                <span>Product Name</span>
                                <span>Packaging Unit</span>
                                <span>Price w/o VAT</span>
                                <span>Amount</span>
                                <span>Supplier</span>
                                <span>Qty</span>
                                <span
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  Base Qty
                                </span>
                                {/* <span>Supply Date</span> */}
                              </div>
                              {line.requisitionLines.map((line: any) => {
                                return (
                                  <RequisitionLine
                                    key={line._id}
                                    data={line}
                                    onChange={requisitionLineChangeHandler}
                                    vendorProductOptions={vendorProductOptions}
                                    onChangeVendorProductId={
                                      vendorProductIdChangeHandler
                                    }
                                    onChangeVendorId={vendorIdChangeHandler}
                                    onDelete={deleteRequisitionLineHandler}
                                    user={user}
                                    requisition={requisition}
                                    setReplaceItemsModal={(value: boolean) => {
                                      setReplaceItemsModal(value);
                                    }}
                                    setReplaceItem={(value: any) => {
                                      setReplaceItem(value);
                                    }}
                                    setSplitItemsModal={(value: boolean) => {
                                      setSplitItemsModal(value);
                                    }}
                                    setSplitItem={(value: any) => {
                                      setSplitItem(value);
                                    }}
                                    setSupplyDateModalOpen={(value: boolean) =>
                                      setChangeSupplyDateModalOpen(value)
                                    }
                                    setSupplyDateItem={(value: any) =>
                                      setSupplyDateItem(value)
                                    }
                                    supplyDate={supplyDate}
                                  />
                                );
                              })}
                            </div>
                          </Panel>
                        );
                      })
                    )}
                    {requisitionFilter === 'By Supplier' &&
                    requisitionLinesByVendor?.length === 0 ? (
                      <h3>No Data Found</h3>
                    ) : (
                      requisitionFilter === 'By Supplier' &&
                      requisitionLinesByVendor.map((line, key) => {
                        return (
                          <Panel
                            key={key}
                            title={
                              <PanelTitle
                                title={line.vendorName}
                                totalItems={line.requisitionLines.length}
                                totalNetAmount={numberWithCommas(
                                  line.totalAmount
                                )}
                                minOrderValue={line.minOrderValue}
                                onBlockEmail={(value: boolean) =>
                                  onBlockVendorEmailsHandler(
                                    value,
                                    line?.vendorId?._id
                                  )
                                }
                                blockVendorEmails={line.blockVendorEmails}
                              />
                            }
                            expanded={expandAll}
                          >
                            <div className={subCategoryWrapper}>
                              <div className={labelsWrapper}>
                                <span>Product Code</span>
                                <span>Product Name</span>
                                <span>Packaging Unit</span>
                                <span>Price w/o VAT</span>
                                <span>Amount</span>
                                <span>Supplier</span>
                                <span>Qty</span>
                                <span
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  Base Qty
                                </span>
                                {/* <span>Supply Date</span> */}
                              </div>
                              {line.requisitionLines.map((line: any) => {
                                return (
                                  <RequisitionLine
                                    key={line._id}
                                    data={line}
                                    onChange={requisitionLineChangeHandler}
                                    vendorProductOptions={vendorProductOptions}
                                    onChangeVendorProductId={
                                      vendorProductIdChangeHandler
                                    }
                                    onChangeVendorId={vendorIdChangeHandler}
                                    onDelete={deleteRequisitionLineHandler}
                                    user={user}
                                    requisition={requisition}
                                    setReplaceItemsModal={(value: boolean) => {
                                      setReplaceItemsModal(value);
                                    }}
                                    setReplaceItem={(value: any) => {
                                      setReplaceItem(value);
                                    }}
                                    setSplitItemsModal={(value: boolean) => {
                                      setSplitItemsModal(value);
                                    }}
                                    setSplitItem={(value: any) => {
                                      setSplitItem(value);
                                    }}
                                    setSupplyDateModalOpen={(value: boolean) =>
                                      setChangeSupplyDateModalOpen(value)
                                    }
                                    setSupplyDateItem={(value: any) =>
                                      setSupplyDateItem(value)
                                    }
                                    supplyDate={supplyDate}
                                  />
                                );
                              })}
                            </div>
                          </Panel>
                        );
                      })
                    )}
                  </Accordion>
                </div>
              </div>
            </div>
          )}
          <div className={textAreaWrapper}>
            <div className={css({ marginBottom: '5px' })}>Remarks:</div>
            <Textarea value={requisition?.remarks} readOnly />
          </div>
        </div>
      )}
      {isLoading && (
        <div className={classes.loading}>
          <Spinner />
        </div>
      )}
      {isProcessing && (
        <div className={classes.loadingWithText}>
          <Spinner />
          <p>Generating Purchase Orders</p>
        </div>
      )}
      {replaceItemsModal && (
        <ReplaceItemsModal
          isOpen={replaceItemsModal}
          onClose={() => {
            setReplaceItemsModal(false);
          }}
          requisition={replaceItem}
          onReplace={replaceRequsitionLineHandler}
        />
      )}
      {splitItemsModal && (
        <SplitItemsModal
          isOpen={splitItemsModal}
          onClose={() => {
            setSplitItemsModal(false);
          }}
          requisition={splitItem}
          onSplit={splitRequsitionLineHandler}
        />
      )}
      {/* /When changing the Date at the header, system to ask: ‘Do you want to apply the new Supply Date to all Items? Yes or No. If user selects Yes, then all the supply Dates of all litne items will be set to this new date. If not, then onle th enew lines will be defaulted to this value  */}
      {changeSupplyDateModalOpen && (
        <SupplyDateModal
          isOpen={changeSupplyDateModalOpen}
          onClose={() => {
            setChangeSupplyDateModalOpen(false);
          }}
          requisitionSupplyDate={supplyDate || new Date()}
          requisitionLine={supplyDateItem}
          onSupplyDateChange={requisitionLinesSupplyDateChangeHandler}
          minDate={new Date()}
        />
      )}
      {invalidSupplyDateModalOpen && (
        <InvalidSupplyDateModal
          isOpen={invalidSupplyDateModalOpen}
          onClose={() => {
            setInvalidSupplyDateModalOpen(false);
          }}
          invalidSupplyDateItems={invalidSupplyDateItems}
        />
      )}
      {customSupplyDateModalOpen && (
        <CustomSupplyDateModal
          isOpen={customSupplyDateModalOpen}
          onClose={() => {
            setCustomSupplyDateModalOpen(false);
          }}
          customSupplyDateItems={customSupplyDateItems}
          requisitionSupplyDate={supplyDate || new Date()}
          onProceed={() => {
            setCustomSupplyDateModalOpen(false);
            ProcessRequisitionHandler();
          }}
        />
      )}
      <Modal
        isOpen={quantityIssueModalOpen}
        onClose={() => setQuantityIssueModalOpen(false)}
        overrides={{
          Root: {
            style: {
              zIndex: 9999,
            },
          },
        }}
      >
        <ModalHeader>Quantity Issue</ModalHeader>
        <ModalBody>
          <p>
            You have the following {quantityIssueItems.length} items with 0 or
            empty values:{' '}
          </p>
          <p>
            {quantityIssueItems.map((item: any) => (
              <span>{item.vendorProductId.label}, </span>
            ))}
          </p>
          <p>Do you want to proceed to convert to PO without these items?</p>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={KIND.tertiary}
            onClick={() => {
              setQuantityIssueModalOpen(false);
            }}
          >
            No, take me back
          </ModalButton>
          <ModalButton
            onClick={() => {
              setQuantityIssueModalOpen(false);
              setQuantityIssueItems([]);
              ProcessRequisitionHandler();
            }}
          >
            Yes, proceed
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

type PanelTitleProps = {
  title: string;
  totalItems: number;
  totalNetAmount?: number;
  minOrderValue?: number;
  showMinOrderValue?: boolean;
  onBlockEmail?: (value: boolean) => void;
  blockVendorEmails?: boolean;
};

const PanelTitle: React.FC<PanelTitleProps> = ({
  title,
  totalItems,
  totalNetAmount,
  minOrderValue,
  showMinOrderValue = true,
  onBlockEmail,
  blockVendorEmails = false,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-between',
        gap: '8px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <span>{title}</span>
        <span style={{ marginLeft: '8px', fontSize: '12px' }}>
          {totalItems} products
        </span>
        {showMinOrderValue && (
          <span style={{ marginLeft: '8px', fontSize: '12px' }}>
            (Min Order Value: {minOrderValue ? minOrderValue : 'N/A'})
          </span>
        )}
      </div>
      {onBlockEmail && (
        <Checkbox
          checked={blockVendorEmails}
          onChange={(e) => onBlockEmail(e.currentTarget.checked)}
          labelPlacement={LABEL_PLACEMENT.right}
          overrides={{
            Root: {
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            },
            Checkmark: {
              style: () => {
                return {
                  width: '14px',
                  height: '14px',
                };
              },
            },
            Label: {
              style: () => {
                return {
                  fontSize: '12px',
                  marginLeft: '4px',
                  paddingLeft: '0px',
                };
              },
            },
          }}
        >
          Skip Email to Supplier
        </Checkbox>
      )}
      <span
        style={{
          marginLeft: '14px',
          textAlign: 'right',
        }}
      >
        Total Net Amount: {totalNetAmount}
      </span>
    </div>
  );
};

export default ProcessRequisition;
