export const dates = [
  {
    id: 'all',
    label: 'All',
  },
  {
    id: 'custom',
    label: 'Custom',
  },
  {
    id: 'today',
    label: 'Today',
  },
  {
    id: 'yesterday',
    label: 'Yesterday',
  },
  {
    id: 'this_week',
    label: 'This Week',
  },
  {
    id: 'last_week',
    label: 'Last Week',
  },
  {
    id: 'this_month',
    label: 'This Month',
  },
  {
    id: 'last_month',
    label: 'Last Month',
  },
  {
    id: 'this_year',
    label: 'This Year',
  },
  {
    id: 'last_year',
    label: 'Last Year',
  },
];

export const dateFilters: any = [
  {
    label: 'Creation Date',
    id: 'createdAt',
  },
  {
    label: 'Release Date',
    id: 'ReleasedAt',
  },
  {
    label: 'Supply Date',
    id: 'SupplyDate',
  },
];
export const userRole: any = [
  {
    label: 'SuperAdmin',
    id: 'SuperAdmin',
  },
  {
    label: 'Admin',
    id: 'Admin',
  },
  {
    label: 'User',
    id: 'User',
  },
  {
    label: 'Purchase Support',
    id: 'PurchaseSupport',
  },
  {
    label: 'Management ReadOnly',
    id: 'ManagementReadOnly',
  },
];

export const voiceFilter: any = [
  {
    label: 'Invoice Date',
    id: 'invoiceDate',
    keys: ['0', '1', '2', '3', '4'],
  },
  {
    label: 'Creation Date',
    id: 'createdAt',
    keys: ['0', '1', '2', '3', '4'],
  },
  {
    label: 'Finalize Date',
    id: 'finalizedAt',
    keys: ['1', '2', '3'],
  },
  {
    label: 'Posting Date',
    id: 'postedAt',
    keys: ['2', '3'],
  },
  {
    label: 'Archived Date',
    id: 'archivedAt',
    keys: ['4'],
  },
];

export const conflictIvoiceFilter: any = [
  {
    label: 'Invoice Date',
    id: 'receivingDate',
    keys: ['Conflict', 'Review', 'Resolved', 'Cancelled', 'All'],
  },
  {
    label: 'Creation Date',
    id: 'createdAt',
    keys: ['Conflict', 'Review', 'Resolved', 'Cancelled', 'All'],
  },
];

export const requisitionFilter: any = [
  {
    label: 'Creation Date',
    id: 'createdAt',
    keys: ['Draft', 'Submitted', 'Processed', 'Archived', 'All'],
  },
  {
    label: 'Supply Date',
    id: 'SupplyDate',
    keys: ['Draft', 'Submitted', 'Processed', 'Archived', 'All'],
  },
  {
    label: 'Submit Date',
    id: 'submitDate',
    keys: ['Submitted', 'Processed', 'All'],
  },
  {
    label: 'Process Date',
    id: 'processedDate',
    keys: ['Processed', 'All'],
  },
  {
    label: 'Archive Date',
    id: 'archivedDate',
    keys: ['Archived', 'All'],
  },
];

export const typeFilterOptions: any = [
  {
    label: 'Manual',
    id: 'Manual',
  },
  {
    label: 'Ordered',
    id: 'Ordered',
  },
  {
    label: 'All',
    id: 'All',
  },
];

export const pageSizeOption = [
  { label: 10, id: '1' },
  { label: 20, id: '2' },
  { label: 50, id: '3' },
  { label: 100, id: '4' },
];

export const sortBy = [
  { label: 'Code', id: 'num' },
  { label: 'Name', id: 'name' },
];

export const sortByAll = [
  { label: 'Total Amount', id: 'totalAmount' },
  { label: 'Created Date', id: 'createdAt' },
  { label: 'Supply Date', id: 'SupplyDate' },
];

export const sortBySubmit = [
  { label: 'Total Amount', id: 'totalAmount' },
  { label: 'Submit Date', id: 'submitDate' },
  { label: 'Supply Date', id: 'SupplyDate' },
];

export const sortByProcess = [
  { label: 'Total Amount', id: 'totalAmount' },
  { label: 'Process Date', id: 'processedDate' },
  { label: 'Supply Date', id: 'SupplyDate' },
];

export const sortByArchive = [
  { label: 'Total Amount', id: 'totalAmount' },
  { label: 'Archive Date', id: 'archivedDate' },
  { label: 'Supply Date', id: 'SupplyDate' },
];

export const sortByTypes = [
  { label: 'Asc', id: 'asc' },
  { label: 'Desc', id: 'desc' },
];

export const tablist = ['Active', 'Inactive', 'All Suppliers'];
