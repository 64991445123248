import React from 'react';
import { useStyletron } from 'styletron-react';
import colors from 'constants/colors';
import { useLocation } from 'react-router-dom';

interface IProps {
  icon: string;
  iconActive: string;
  name: string;
  route: string;
  clicked: () => void;
}

const SideBarLink = ({ icon, iconActive, name, route, clicked }: IProps) => {
  const [css] = useStyletron();
  const location = useLocation();
  const isActive = location.pathname.includes(route);

  const wrapperStyles = css({
    display: 'flex',
    width: '100%',
    maxWidth: '200px',
    padding: '8px 16px',
    alignItems: 'center',
    marginBottom: '12px',
    cursor: 'pointer',
  });
  const wrapperActiveStyles = css({
    display: 'flex',
    padding: '8px 16px',
    alignItems: 'center',
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '12px',
    backgroundColor: colors.primaryGreen,
    marginBottom: '12px',
    cursor: 'pointer',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    marginRight: '8px',
    filter:
      'invert(31%) sepia(72%) saturate(259%) hue-rotate(181deg) brightness(90%) contrast(94%)',
  });
  const iconActiveStyles = css({
    width: '20px',
    height: '20px',
    marginRight: '8px',
  });
  const buttonStyles = css({
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontFamily: 'poppins-regular',
    fontSize: '16px',
    color: colors.darkBlue,
    cursor: 'pointer',
  });
  const buttonActiveStyles = css({
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontFamily: 'poppins-medium',
    fontSize: '16px',
    color: colors.white,
    cursor: 'pointer',
  });

  return (
    <div
      className={isActive ? wrapperActiveStyles : wrapperStyles}
      onClick={clicked}
    >
      <img
        className={isActive ? iconActiveStyles : iconStyles}
        src={isActive ? iconActive : icon}
        alt={name}
      />
      <button
        className={isActive ? buttonActiveStyles : buttonStyles}
        type="button"
      >
        {name}
      </button>
    </div>
  );
};

export default SideBarLink;
