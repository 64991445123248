import { createContext, useReducer } from 'react';

import { IAuthUser } from 'modules/shared/interfaces/auth.interface';
import { AuthService } from 'core/services/auth';
import { AUTH_TOKEN, AUTH_USER } from 'constants/auth';
import { NOTIFICATION_POPUP } from 'constants/notification';

export enum DispatcherAction {
  Login = 'Login',
  Logout = 'Logout',
  NotificationPopup = 'NotificationPopup',
  JWTRefresh = 'JWTRefresh',
}

interface IAppStateData {
  token: string | null;
  user: IAuthUser | null;
  notificationPopupEnabled: boolean;
}

type ReducerAction =
  | { type: DispatcherAction.Login; payload: null }
  | { type: DispatcherAction.Logout; payload: null }
  | { type: DispatcherAction.JWTRefresh; payload: null }
  | { type: DispatcherAction.NotificationPopup; payload: boolean };

const authService = new AuthService();

const isNotificationPopupEnabled = () => {
  const notificationPopup = localStorage.getItem(NOTIFICATION_POPUP);
  if (notificationPopup && notificationPopup === '0') {
    return false;
  }
  return true;
};

const initialState: any = {
  token: authService.getAuthToken(),
  user: authService.getAuthUser(),
  notificationPopupEnabled: isNotificationPopupEnabled(),
};

const reducer = (state: IAppStateData, action: ReducerAction) => {
  const { type, payload } = action;

  const getAuthToken = () => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) return token;
    return null;
  };

  const getAuthUser = () => {
    const user = localStorage.getItem(AUTH_USER);
    if (user) return JSON.parse(user);
    return null;
  };

  switch (type) {
    case DispatcherAction.Login:
      const authData = {
        token: getAuthToken(),
        user: getAuthUser(),
      };
      return {
        ...state,
        ...authData,
      };
    case DispatcherAction.Logout:
      return {
        ...state,
        token: null,
        user: null,
      };
    case DispatcherAction.NotificationPopup:
      return {
        ...state,
        notificationPopupEnabled: payload,
      };
    case DispatcherAction.JWTRefresh:
      return {
        ...state,
        token: getAuthToken(),
      };
    default:
      return { ...state };
  }
};

export const AppContext = createContext({ ...initialState });

export const AppContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  function dispatcher(type: DispatcherAction, payload: any) {
    dispatch({ type, payload });
  }

  return (
    <AppContext.Provider value={{ state, dispatcher }}>
      {children}
    </AppContext.Provider>
  );
};
