import React from 'react';

interface IProps {
  children: React.ReactNode;
  outsideClicked: (val: boolean) => void;
}

const OutsideAlerter = ({ children, outsideClicked }: IProps) => {
  const wrapperRef: any = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event: any) =>
      outsideClicked(
        wrapperRef.current && !wrapperRef.current.contains(event.target)
      );

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
