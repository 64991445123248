import logoURL from 'assets/images/logo_white.png';
import bluelogoURL from 'assets/images/logo_blue.png';
import { useStyletron } from 'styletron-react';

type Props = {
  height?: number;
  width?: number;
  logoColor?: string;
  clicked: () => void;
};

const Logo = ({ height, width, logoColor, clicked }: Props) => {
  const [css] = useStyletron();
  const _width = width ? width + 'px' : 'auto';
  const _height = height ? height + 'px' : 'auto';
  return (
    <>
      <img
        className={css({
          width: _width,
          height: _height,
          cursor: 'pointer',
          '@media (max-width: 540px)': {
            height: `${logoColor === 'blue' ? '32px' : height}`,
          },
        })}
        src={logoColor === 'blue' ? bluelogoURL : logoURL}
        alt="Gourmet Gulf"
        onClick={clicked}
      />
    </>
  );
};
export default Logo;
