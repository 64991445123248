import { Spinner } from 'baseui/spinner';
import { useStyletron, withStyle } from 'styletron-react';

const commonSpinnerStyles = {
  borderLeftWidth: '12px',
  borderRightWidth: '12px',
  borderTopWidth: '12px',
  borderBottomWidth: '12px',
  borderTopColor: 'pink',
};

const LargeSpinner = withStyle(Spinner, {
  ...commonSpinnerStyles,
  width: '96px',
  height: '96px',
});

const SmallSpinner = withStyle(Spinner, {
  ...commonSpinnerStyles,
  width: '48px',
  height: '48px',
  borderLeftWidth: '6px',
  borderRightWidth: '6px',
  borderTopWidth: '6px',
  borderBottomWidth: '6px',
});

const Loader = ({ size = 'large' }) => {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: size === 'small' ? '100%' : '100vh',
        width: size === 'small' ? '100%' : '100vw',
      })}
    >
      {size === 'small' ? <SmallSpinner /> : <LargeSpinner />}
    </div>
  );
};

export default Loader;
